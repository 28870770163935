import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Animated } from "react-animated-css";
import AOS from 'aos';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { getData, setData } from "../../services/storage";

const recaptchaRef = React.createRef();

class Career extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.state = {
            files: [],
            language: getData('language'),
            placeholder: "",
            name: "",
            file: "",
        };
    }

    onChange(e) {
        var files = e.target.files;
        var filesArr = Array.prototype.slice.call(files);
        this.setState({ file: filesArr[0] })
        this.setState({ files: [...this.state.files, ...filesArr] });
        this.setState({ placeholder: filesArr[0] ? filesArr[0].name : this.state.language === false ? "Selecionar arquivo do currículo..." : "Select the file of your curriculum..." })
    }

    handleFormSubmit(event) {
        event.preventDefault();
    }

    removeFile(f) {
        this.setState({ files: this.state.files.filter(x => x !== f) });
    }

    sendMail() {
        const recaptchaValue = recaptchaRef.current.getValue();

        const formData = new FormData();
    
        formData.append('name', this.state.name)
        formData.append('file', this.state.file)
        formData.append('tokenRecaptcha', recaptchaValue)
        formData.append('language', this.state.language)

        //console.log('formData', formData);

        axios.post('mail/curriculum.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            .then(response => {
                alert(`${response.data.message}`); 
                this.setState({ 
                    name: '',
                    placeHolder: ''   
                })
                recaptchaRef.current.reset();
             })
       
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
        // or simply just AOS.init();
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="Se você é apaixonado por tecnologias e gosta de enfrentar desafios, a CodeScript é seu lugar ideial para construir uma carreira."></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>
                <Header />
                <div className="landing-hero-area menu-bg pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated animationIn="flipInX" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                                        <h2 className="text-white">
                                            {this.state.language === false
                                                ? "Trabalhe conosco!"
                                                : "Work alongside us!"
                                            }
                                        </h2>
                                        <p className="text-white">
                                            {this.state.language === false
                                                ? "Você é apaixonado por tecnologias? Gosta de enfrentar desafios?"
                                                : "Do you love technology and enjoy taking on new challenges?"
                                            }
                                            <br />
                                            {this.state.language === false
                                                ? "Nos envie seu currículo e vamos conversar 😊"
                                                : "Send your curriculum to us and let’s chat together 😊"
                                            }
                                        </p>
                                        <form name="formTeste">
                                            <input
                                                className="input-about"
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={this.state.name}
                                                onChange={e => this.setState({ name: e.target.value })}
                                                placeholder={this.state.language === false ? "Digite seu nome..." : "Type your name..."}
                                            />
                                            <div>
                                                <input
                                                    className="input-file"
                                                    type="text"
                                                    name="placeholder"
                                                    placeholder={!!this.state.placeholder ? this.state.placeholder : this.state.language === false ? "Selecionar arquivo do currículo..." : "Select the file of your curriculum..."}
                                                    readOnly
                                                />
                                                <label className="input-file-upload">
                                                    <input id="pdfFile" name="pdfFile" type="file" accept="application/pdf" onChange={this.onChange} />
                                                    {this.state.language === false
                                                        ? "Procurar"
                                                        : "Browse"
                                                    }
                                                </label>
                                            </div>
                                        </form>
                                    </Animated>

                                    <br />
                                    <ReCAPTCHA
                                        sitekey="6Lf_SLgdAAAAABWHLrCxgKxrd_97HUx1qfo7HLuH"
                                        ref={recaptchaRef}
                                    />
                                    <br />

                                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1250}>
                                        <button onClick={() => this.sendMail()} className="ar-button style-flat" type="button">
                                            <span className="button-text">
                                                {this.state.language === false
                                                    ? "ENVIAR CURRÍCULO"
                                                    : "UPLOAD CURRICULUM"
                                                }
                                            </span>
                                        </button>
                                    </Animated>

                                </div>

                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated
                                        animationIn="bounceInRight"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        animationInDelay={200}
                                    >
                                        <img
                                            src={require('../../assets/images/business/career.svg').default}
                                            alt={this.state.language === false
                                                ? 'Carreira'
                                                : 'Career'
                                            }
                                        />
                                    </Animated>
                                    <div className="spacing--1" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Start Footer Area*/}
                <Footer />
                {/* End Footer Area*/}
            </Fragment>
        );
    }
}

export default Career;

