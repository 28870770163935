import { Helmet } from "react-helmet";
import React, { Component, Fragment } from "react";
import BoxIconThree from '../../elements/component/box-icon/BoxIconThree';
import Footer from '../../components/footer/Footer';
import Header from "../../components/header/Header";
import Iconbox from '../../components/iconbox/Iconbox';
import PricingBoxFour from "../../components/pricingBox/PricingBoxFour";
import BoxIconTwo from "../../components/box-icon-two/BoxIconTwo";
import { Animated } from "react-animated-css";
import AOS from 'aos';
import ModalVideo from 'react-modal-video';
import { getData, setData } from "../../services/storage";

class Home extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            language: getData('language'),
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    componentDidMount() {
        document.documentElement.scrollTop = 1

        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }

        AOS.init({
            duration: 2000
        });
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="Tecnologia de última geração integrada dentro de qualquer negócio."></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>
                <Header /*translate={english}*/ />
                {/* Start Slider Area */}
                <div className="landing-hero-area menu-bg">
                    <div className="container">
                        <div className="row">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--50 pt_sm--0">
                                    <Animated animationIn="flipInX" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                                        <h2 className="title-site">
                                            {this.state.language === false ? 'Bem-vindo à' : 'Welcome to'}

                                        </h2>
                                        <h2 className="codeScript-text">
                                            CodeScript  {this.state.language === false
                                                ? 'Tecnologia'
                                                : 'Technology'
                                            }
                                        </h2>
                                    </Animated>
                                    <br />
                                    <div className="row d-flex justify-content-around">
                                        <Animated animationIn="slideInLeft" animationOut="fadeOut" isVisible={true} >
                                            <PricingBoxFour />
                                        </Animated>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="text-center landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated animationIn="slideInDown" animationOut="fadeOut" isVisible={true} >
                                        <img
                                            className="w-75 d-md-none d-md-none"
                                            src={require('../../assets/images/home/template.svg').default}
                                            alt="CodeScript"
                                        />
                                        <div className="d-none d-md-block d-md-none">
                                            <img
                                                className="w-75"
                                                src={require('../../assets/images/home/videogame.svg').default}
                                                alt="CodeScript"
                                            />
                            
                                            <a href="/game">
                                                <button style={{ marginTop: 20 }} className="ar-button style-flat" type="button">
                                                    <span className="button-text">{this.state.language === false ? 'JÁ CONHECE NOSSO GAME?' : 'HAVE YOU SEEN OUR GAME?'}</span>
                                                </button>
                                            </a>
                                        </div>
                                    </Animated>
                                    <Animated animationIn="slideInRight" animationOut="fadeOut" isVisible={true} style={{ marginTop: 60 }}>
                                        <BoxIconTwo />
                                    </Animated>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background">
                    <div className="popup-video thine-line ptb--50">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h2 className="text-blue">
                                                {this.state.language === false
                                                    ? 'Conheça a CodeScript!'
                                                    : 'Meet CodeScript!'
                                                }
                                            </h2>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h4 className="text-blue">
                                                {this.state.language === false
                                                    ? 'Transforme suas ideias em conquistas!'
                                                    : 'Transform your ideas into achievements!'
                                                }
                                            </h4>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <a href="/contact">
                                                <button className="button-blue" type="submit">
                                                    <span className="button-text">
                                                        {this.state.language === false
                                                            ? 'ENTRAR EM CONTATO'
                                                            : 'CONTACT US'
                                                        }
                                                    </span>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* Start Single Popup */}
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="popup-video-inner position-relative">
                                        <div data-aos="fade-left" data-aos-duration="500">
                                            <div className="thumbnail">
                                                <img className="w-100 video-home" src={require('../../assets/images/home/capa-YT.png').default} alt="Youtube" />
                                            </div>
                                            <div className="icon-position">
                                                <div className="popup-video">
                                                    <div className="video-marker">
                                                        <div className="wave-pulse wave-pulse-1"></div>
                                                        <div className="wave-pulse wave-pulse-2"></div>
                                                    </div>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='Rbz7GGBr7hE' onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="video-popup button-solid-yellow" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Popup */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blue-background">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center text-white">
                                {this.state.language === false
                                    ? 'Nossa metodologia de trabalho'
                                    : 'Our development methodology'
                                }
                            </h2>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="500">
                            <div className="gradation-area ptb--80">
                                <Iconbox language={this.state.language} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-up" data-aos-duration="500">
                                        <h2 className="title-justify-center text-blue">
                                            {this.state.language === false
                                                ? 'Áreas de atuação'
                                                : 'Areas of activity'
                                            }
                                        </h2>
                                    </div>
                                    <div data-aos="fade-up" data-aos-duration="500">
                                        <div className="row justify-content-center">
                                            <p className="text-blue">
                                                {this.state.language === false
                                                    ? 'Com o passar do tempo, adquirimos experiência desenvolvendo projetos em diversas áreas. Conheça as áreas que trabalhamos atualmente.'
                                                    : 'Over the years, we acquired experience developing all sorts of projects in distinct areas. These are the areas of expertise we currently work with.'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="500">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="box-icon pb--120">
                                        <div className="container">
                                            <BoxIconThree language={this.state.language} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blue-background ptb--50">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="mt_sm--30 col-lg-7 col-md-7 col-sm-7 col-12">
                                <div data-aos="fade-up" data-aos-duration="500">
                                    <h5 className="text-white">
                                        {this.state.language === false
                                            ? 'Do Brasil para o mundo, a CodeScript está situada em Passo Fundo, no Rio Grande do Sul.'
                                            : 'From Brazil to the world, CodeScript is located in Passo Fundo, Rio Grande do Sul.'
                                        }
                                    </h5>
                                </div>
                                <br />
                                <div data-aos="fade-up" data-aos-duration="500">

                                    <h5 className="text-white">
                                        {this.state.language === false
                                            ? 'Integramos uma extensa rede de inovação e networking, com um ambiente acolhedor aos visitantes.'
                                            : 'We integrate an expensive network of business and innovation, with a welcoming environment for visitors.'
                                        }
                                    </h5>
                                </div>
                                <br />
                                <div data-aos="fade-up" data-aos-duration="500">
                                    <h5 className="text-white">
                                        {this.state.language === false
                                            ? 'Caso deseje nos visitar, combine conosco que nossas portas estão abertas para recebê-los (acompanhados de um bom café ☕ ou um bom chimarrão 🧉).'
                                            : 'Would you ever wish to meet with us contact to arrange an appointment, as we are always open to receive visits (accompanied of a good cup of coffee ☕ or our traditional yerba mate, the Chimarrão 🧉).'
                                        }
                                    </h5>
                                </div>
                                <br />
                                <div data-aos="fade-up" data-aos-duration="500">
                                    <a href="/contact">
                                        <button className="ar-button style-flat" type="submit">
                                            <span className="button-text">
                                                {this.state.language === false
                                                    ? 'ENTRAR EM CONTATO'
                                                    : 'CONTACT US'
                                                }
                                            </span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
                                <div data-aos="fade-left" data-aos-duration="500">
                                    <img
                                        src={require('../../assets/images/home/conversation_tche.svg').default}
                                        alt="Rio Grande do Sul"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Footer Area*/}
                <Footer />
                {/* End Footer Area*/}
            </Fragment>
        );
    }
}

export default Home;
