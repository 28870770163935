import React, { Component } from "react";
import { getData } from "../../services/storage";

class PricingBoxFour extends Component{
    constructor() {
        super()
        this.state = {
            language: getData('language'),
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className="row">

                    {/* Start Single Pricing Table */}
                    <div className="col-lg-6 col-xl-6 col-md-6 col-12">
                        <div className="pricing-table--4 heighted">
                            <div className="inner">
                                <div className="pricing-header">
                                    <div className="image">
                                        <img className="w-50" src={require('../../assets/images/home/web3.svg').default} alt="Web 3"/>
                                    </div>
                                    <h5 className="title">WEB 3.0</h5>
                                </div>
                                <div className="pricing-content">
                                    <p className="text-left">
                                        {this.state.language === false
                                            ? 'Uma internet descentralizada, com liberdade nas transações, proteção à seus dados e quem sabe, um mundo inteiramente novo e digital.'
                                            : 'A decentralized internet, with freedom of transactions, protection of your data and who knows, an entirely new and digital world.'
                                        }
                                    </p>
                                </div>
                                <div className="pricing-button">
                                    <a href="/web3">
                                        <button className="ar-button style-flat" type="submit">
                                            <span className="button-text">
                                                {this.state.language === false
                                                    ? 'Ver Mais'
                                                    : 'View More'
                                                }
                                            </span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Pricing Table */}

                    {/* Start Single Pricing Table */}
                    <div className="col-lg-6 col-xl-6 col-md-6 col-12 mt_sm--30">
                        <div className="pricing-table--4 heighted">
                            <div className="inner">
                                {/* <div className="feature-mark">
                                    <span>NEW</span>
                                </div> */}
                                <div className="pricing-header">
                                    <div className="image">
                                    <img className="w-50" src={require('../../assets/images/home/outsourcing.svg').default} alt="Outsourcing"/>
                                    </div>
                                    <h5 className="title">OUTSOURCING</h5>
                                </div>
                                <div className="pricing-content">
                                    <p className="text-left">
                                        {this.state.language === false
                                            ? 'Foque no que é importante, o seu negócio. Deixe que a Code cuide da parte tecnologica da sua empresa e descomplique o que parece difícil.'
                                            : 'Focus on what\'s important, your business. Let Code take care of the technology part of your business and simplify what seems difficult.'
                                        }
                                    </p>
                                </div>
                                <div className="pricing-button">
                                    <a href="/outsourcing">
                                        <button className="ar-button style-flat" type="submit">
                                            <span className="button-text">
                                            {this.state.language === false
                                                ? 'Ver Mais'
                                                : 'View More'
                                            }
                                            </span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Pricing Table */}
 
                </div>
            </React.Fragment>
        )
    }
}



export default PricingBoxFour;

