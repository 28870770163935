export const setData = (value) => {
    window.localStorage.setItem('language', value)
}

export const getData = name => {
    const item = window.localStorage.getItem(name)

    if (item) {
        return JSON.parse(item)
    }

    return null
}
