import { Helmet } from "react-helmet";
import React, { useEffect, useState, Fragment } from "react";
import { FaBitcoin, FaEthereum } from 'react-icons/fa';
import Footer from '../../components/footer/Footer';
import Header from "../../components/header/Header";
import { getData } from "../../services/storage";
import { Animated } from "react-animated-css";
import BoxIconNineWeb3 from "../../components/box-icon-nine/BoxIconNineWeb3";
import AOS from 'aos';

function Web3() {

    const [language] = useState(getData('language'));
    const bitcoinWallet = 'bc1qjpycxa348tr8rmelpzlr7cyc5hxuazvunw2dm2';
    const ethereumWallet = '0xeE920207664E977D4b08179dD192D0A4b28cfa67';

    useEffect(() => {
        document.documentElement.scrollTop = 0
    }, []);

    AOS.init({
        duration: 2000
    });

    return (
        <Fragment>
            <Helmet>
                <title>CodeScript</title>
                <meta name="description" content="Entre em contato conosco!"></meta>
                <meta name="keywords" content="contato, projetos, ideias, desenvolvimento, aplicações, app, aplicativos" />
            </Helmet>

            <Header />
            <>
            </>
            <div className="landing-hero-area menu-bg">
                <div className="container">
                    <div className="row">
                        <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="landing-hero-inner pt--50 pt_md--50 pt_sm--0">
                                <Animated animationIn="flipInX" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                                    <h2 className="text-white">
                                        {language === false
                                            ? 'Nós estamos na Web 3.0'
                                            : 'We are on Web 3.0'
                                        }
                                    </h2>
                                    <br />
                                    <h4 className="text-white">
                                        {language === false
                                            ? 'A nova fase da internet chegou, a Web 3.0!'
                                            : 'The new age of the internet has arrived, Web 3.0!'
                                        }
                                    </h4>
                                    <h5 className="text-white">
                                        {language === false
                                            ? 'A CodeScript já está navegando neste mundo de possibilidades com diferentes tipos de tecnologias!'
                                            : 'CodeScript is already navigating this world of possibilities with different types of technologies!'
                                        }
                                    </h5>
                                    
                                </Animated>
                                <br />

                                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1250}>
                                    <h4 className="text-white mt-2 mb-5">
                                        {language === false
                                            ? 'Aceitamos pagamento em cripto, selecione a carteira!'
                                            : 'We accept payment in crypto, select wallet!'
                                        }
                                    </h4>

                                    <button className="ar-button style-flat ml-3" type="submit"
                                        onClick={() => {
                                            navigator.clipboard.writeText(bitcoinWallet);
                                            alert('Carteira copiada');
                                        }}
                                    >
                                        <FaBitcoin />
                                        <span className="button-text">
                                            {language === false
                                                ? ' CARTEIRA BITCOIN'
                                                : ' BITCOIN WALLET'
                                            }
                                        </span>
                                    </button>
                                    
                                    <button className="ar-button style-flat ml-3 mt-2" type="submit"
                                        onClick={() => {
                                            navigator.clipboard.writeText(ethereumWallet);
                                            alert('Carteira copiada');
                                        }}
                                    >
                                        <FaEthereum />
                                        <span className="button-text">
                                            {language === false
                                                ? ' CARTEIRA ETHEREUM'
                                                : ' ETHEREUM WALLET'
                                            }
                                        </span>
                                    </button>
                                    
                                </Animated>
                                <br />
                            </div>
                        </div>
                        <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                <Animated animationIn="slideInDown" animationOut="fadeOut" isVisible={true} >
                                    <img
                                        src={require('../../assets/images/web3/web3.svg').default}
                                        alt="Web 3.0"
                                    />
                                </Animated>
                                <div className="spacing--1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gray-background">
                <div className="call-to-action-wrapper call-to-action-bg">
                    <div className="call-to-action--1 variation--2 ptb--40">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="inner ptb--50">
                                        <div className="content">
                                        <div data-aos="fade-up" data-aos-duration="500" className="aos-init aos-animate">
                                                <h3 className="text-blue">
                                                    CodeScript NFTs
                                                </h3>
                                                <h5 className="text-blue">
                                                    {language === false
                                                        ? 'Acesse o link abaixo e confira a coleção de NFTs do nosso time'
                                                        : 'Visit the link below and check out our team\'s collection of NFTs.'
                                                    }
                                                </h5>
                                                <a href="https://opensea.io/collection/team-codescript" target="_blank" rel="noreferrer">
                                                    <button className="button-blue" type="submit">
                                                        <span className="button-text">
                                                        {language === false
                                                            ? 'Coleção OpenSea'
                                                            : 'OpenSea Collection'
                                                        }
                                                        </span>
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div data-aos="fade-left" data-aos-duration="500">
                                        <div className="thumbnail">
                                            <img src={require('../../assets/images/web3/nft.svg').default} alt="NFT" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blue-background">
                <div className="call-to-action-wrapper call-to-action-bg">
                    <div className="call-to-action--1 variation--2 ptb--40">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div data-aos="fade-right" data-aos-duration="500">
                                        <div className="thumbnail">
                                            <img src={require('../../assets/images/web3/ipfs.svg').default} alt="IPFS" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="inner ptb--50">
                                        <div className="content">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <h3 className="text-white">
                                                    {language === false
                                                        ? 'Sistema de Arquivos Interplanetário (IPFS)'
                                                        : 'InterPlanetary File System (IPFS)'
                                                    }
                                                </h3>
                                                <h5 className="text-white">
                                                    {language === false
                                                        ?   <div>
                                                                <div>
                                                                    IPFS é um protocolo e uma rede descentralizada, com a finalidade de distribuir arquivos ponto a ponto 
                                                                    sem a necessidade de um sevidor web centralizado.
                                                                </div>
                                                                <div className="mt-3">
                                                                    Atualmente o protocolo é nativo apenas no navegador Brave, entretanto através de gateways podemos 
                                                                    trazer os arquivos para o tradicional HTTP.
                                                                </div>
                                                                <div className="mt-3">
                                                                    Acesse os links abaixo(pode demorar alguns segundos para abrir):
                                                                </div>
                                                            </div>
                                                        : <div>
                                                            <div>
                                                                IPFS is a protocol and a decentralized network, for the purpose of distributing files peer-to-peer 
                                                                without the need for a centralized web server.
                                                            </div>
                                                            <div className="mt-3">
                                                                Currently the protocol is native only in the Brave browser, however through gateways we can bring 
                                                                the files to the traditional HTTP.
                                                            </div>
                                                            <div className="mt-3">
                                                                Access the links below (may take a few seconds to open):
                                                            </div>
                                                        </div>
                                                    }
                                                </h5>
                                                <a href="ipfs://QmY2eCfahnac88nbSZp91Lv7tner6k7zZcKzBFo5E5Bcao" target="_blank" rel="noreferrer">
                                                    <button className="button-blue ml-2" type="submit">
                                                        <span className="button-text">
                                                            {language === false
                                                                ? 'Abrir IPFS no Brave'
                                                                : 'Open IPFS in Brave'
                                                            }
                                                        </span>
                                                    </button>
                                                </a>
                                                <a href="https://ipfs.io/ipfs/QmY2eCfahnac88nbSZp91Lv7tner6k7zZcKzBFo5E5Bcao" target="_blank" rel="noreferrer">
                                                    <button className="button-blue ml-2 mt-2" type="submit">
                                                        <span className="button-text">
                                                            {language === false
                                                                ? 'Abrir IPFS com HTTP'
                                                                : 'Open IPFS with HTTP'
                                                            }
                                                        </span>
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gray-background">
                <div data-aos="fade-left" data-aos-duration="1000">
                    <div className="box-icon ptb--40">
                        <h3 className="text-blue text-center">
                            {language === false
                                ? 'Aceitamos pagamentos em Bitcoin e Ethereum'
                                : 'We accept payments in Bitcoin and Ethereum'
                            }
                        </h3>
                        <div className="container">
                            <BoxIconNineWeb3 bitcoinWallet={bitcoinWallet} ethereumWallet={ethereumWallet}/>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment >
    );
}

export default Web3; 
