import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import AOS from 'aos';
import { getData, setData } from "../../../services/storage";

class Medicine extends Component {

    state = { language: getData('language') }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
        // or simply just AOS.init();
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

        this.animation()
    }

    animation = () => {

    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="O objetivo da tecnologia na medicina é encontrar formas de como melhorar os processos que são realizados e auxiliar na pesquisa que leva ao descobrimento de novos tratamentos."></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>
                <Header />
                <div className="bg-medicine">
                    <div className="container">
                        <h2 className="title-banner">
                            {this.state.language === false
                                ? "Tecnologia para o bem-estar"
                                : "Technology for well-being"
                            }
                            <br />
                            {this.state.language === false
                                ? "e para a melhoria de processos"
                                : "and for improving processes"
                            }
                        </h2>
                    </div>
                </div>
                <div className="gray-background pt--20">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false ? 'Medicina' : 'Medicine'}
                            </h2>
                        </div>
                        <div className="row align-items-center pt--30">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div data-aos="fade-right" data-aos-duration="500">
                                        <div className="border-left-img">
                                            <img className="img-services" src={require('../../../assets/images/medicine/solutions.jpg').default} alt="Medicine" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div className="tab-inner history-inner">
                                        <div className="inner">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "A Medicina moderna avançou de forma extraordinária no último século, nas formas como compreendemos o corpo e mente dos seres humanos, no tratamento e prevenção das doenças e em como respondemos rapidamente a crises."
                                                        : "Modern medicine advanced extraordinarily in the latest century, among the ways we comprehend the body and mind of human beings, in the treatment and prevention of diseases and in how we rapidly respond to crisis."
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "Muito destes méritos são divididos com a própria evolução da Tecnologia. Ambas estas áreas trabalham conjuntas em sinergia, possuindo este objetivo de ultrapassar os limites que até então são conhecidos."
                                                        : "A lot of these merits are shared with the very evolution of Technology. Both fields work together in synergy, having this objective of pushing beyond the known limits."
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background pt--20">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false
                                    ? "Tecnologia"
                                    : "Technology"
                                }
                            </h2>
                        </div>
                        <div className="row align-items-center pt--20">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div className="tab-inner history-inner">
                                        <div className="inner">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "O objetivo da tecnologia na medicina é encontrar formas de como melhorar os processos que são realizados e auxiliar na pesquisa que leva ao descobrimento de novos tratamentos."
                                                        : "The objective of technology in medicine is finding forms of improving the procedures that are performed and aiding in the research that takes to the discovery of new treatments."
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "Inovações com aplicativos e em equipamentos de Internet das Coisas oferecem tanto para médicos como também para os pacientes mais precisão e diversidade de dados para os diagnósticos."
                                                        : "Innovations with applications and in IoT equipment offer both to physicians and patients more precision and diversity of data to make diagnosis."
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div data-aos="fade-left" data-aos-duration="500">
                                        <div className="border-right-img">
                                            <img className="img-services" src={require('../../../assets/images/medicine/saudeTech.jpg').default} alt="Medicine" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="gray-background pt--100 pb--100">
                    <div className="container">
                        <div className="d-flex justify-content-center">
                            <div className="heart-rate" style={{ marginRight: 100 }}>
                                <svg version="1.0" x="0px" y="0px" width="150px" height="73px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73">
                                    <polyline fill="none" stroke="#0285f4" strokeWidth="3" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
                                    />
                                </svg>
                                <div className="fade-in"></div>
                                <div className="fade-out"></div>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="500">
                                <h2>
                                    {this.state.language === false
                                        ? "Soluções"
                                        : "Solutions"
                                    }
                                </h2>
                            </div>
                            <div className="heart-rate" style={{ marginLeft: 100 }}>
                                <svg version="1.0" x="0px" y="0px" width="150px" height="73px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73">
                                    <polyline fill="none" stroke="#0285f4" strokeWidth="3" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
                                    />
                                </svg>
                                <div className="fade-in"></div>
                                <div className="fade-out"></div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                <div className="tab-inner history-inner">
                                    <div className="inner">
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h4 className="text-blue">
                                                {this.state.language === false
                                                    ? "A CodeScript almeja dispor da mesma ambição refletida em suas demais "
                                                    : "CodeScript aims to provide the same ambition reflected among its"
                                                }
                                                <br />
                                                {this.state.language === false
                                                    ? "áreas de atuação para realizar projetos inovadores para a área da Saúde."
                                                    : "other fields of activity to realize innovative projects to healthcare."
                                                }
                                            </h4>
                                        </div>
                                        <div className="single-button mt--40">
                                            <a href="/contact">
                                                <button className="button-medicine full-width" type="submit">
                                                    <span className="button-text">
                                                        {this.state.language === false
                                                            ? "ENTRAR EM CONTATO"
                                                            : "CONTACT US"
                                                        }
                                                    </span>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blue-background pb--80 pt--40">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <div className="row">
                                <div className="col">
                                    <h3 className="text-investment text-white">
                                        {this.state.language === false
                                            ? "Conheça um pouco mais sobre:"
                                            : "Get to know more about:"
                                        }
                                    </h3>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="/logistic">
                                        <div className="demo_wrap">
                                            <h3>
                                                
                                                {this.state.language === false
                                                    ? "Logística Veicular"
                                                    : "Vehicle Logistics"
                                                }
                                            </h3>
                                            <img src={require(`../../../assets/images/boxIcon/logistic.jpg`).default} alt="iot" />
                                        </div>
                                    </a>
                                </div>
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="/agro">
                                        <div className="demo_wrap">
                                            <h3>
                                                {this.state.language === false
                                                    ? "Agronegócio"
                                                    : "Agribusiness"
                                                }
                                            </h3>
                                            <img src={require(`../../../assets/images/boxIcon/agro.jpg`).default} alt="Medicine" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Footer Area*/}
                <Footer />
                {/* End Footer Area*/}
            </Fragment>
        );
    }
}

export default Medicine;
