import { Helmet } from "react-helmet";
import React, { Component, Fragment } from "react";
import Footer from '../../components/footer/Footer';
import Header from "../../components/header/Header";
import { getData, setData } from "../../services/storage";

class Cookies extends Component {

    state = { language: getData('language') }

    componentDidMount() {
        document.documentElement.scrollTop = 0

        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="Política de Cookies!"></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>

                <Header />

                <div className="landing-hero-area menu-bg pt--30">
                    <div className="container">
                        {this.state.language === false ? (
                            <>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h2 className="text-white">
                                                Política de Cookies
                                            </h2>
                                            <p className="text-white">
                                                A presente Política de Cookies é um documento complementar à Política de Privacidade da CodeScript, disponível neste link: <a className="link-hover privacy" href="/privacy">Política de Privacidade</a>. Aqui, você encontrará informações objetivas e claras sobre o que são Cookies, quais Cookies utilizamos em nossas aplicações, qual papel desempenham e como configurá-los.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                1. O que são Cookies ?
                                            </h3>
                                            <p className="text-white">
                                                Cookies são pequenos arquivos de texto ou fragmentos de informação que são baixados em seu computador, smartphone ou qualquer outro dispositivo com acesso à internet quando você visita nossa aplicação.
                                            </p>
                                            <p className="text-white">
                                                Eles contêm informações sobre a sua navegação em nossas páginas e retêm apenas informações relacionadas a suas preferências.
                                            </p>
                                            <p className="text-white">
                                                Assim, essa página consegue armazenar e recuperar os dados sobre os seus hábitos de navegação, de forma a melhorar a experiência de uso, por exemplo. É importante frisar que eles não contêm informações pessoais específicas, como dados sensíveis ou bancários.
                                            </p>
                                            <p className="text-white">
                                                O seu navegador armazena os cookies no disco rígido, mas ocupam um espaço de memória mínimo, que não afetam o desempenho do seu computador. A maioria das informações são apagadas logo ao encerrar a sessão, como você verá no próximo tópico.
                                            </p>
                                            <h4 className="text-white">
                                                1.1. Tipos de Cookies
                                            </h4>
                                            <p className="text-white">
                                                Os cookies, quanto a seus proprietários, podem ser:
                                            </p>
                                            <p className="text-white">
                                                1. Cookies proprietários: são cookies definidos por nós ou por terceiros em nosso nome.
                                            </p>
                                            <p className="text-white">
                                                2. Cookies de terceiros: são cookies definidos por terceiros confiáveis em nossa aplicação.
                                            </p>
                                            <p className="text-white">
                                                Os cookies, quanto ao seu tempo de vida, podem ser:
                                            </p>
                                            <p className="text-white">
                                                1. Cookies de sessão ou temporários: são cookies que expiram assim que você fecha o seu navegador, encerrando a sessão.
                                            </p>
                                            <p className="text-white">
                                                2. Cookies persistentes ou permanentes: são cookies que permanecem no seu dispositivo durante um período determinado ou até que você os exclua.
                                            </p>
                                            <p className="text-white">
                                                Os cookies, quanto a sua finalidade, podem ser:
                                            </p>
                                            <p className="text-white">
                                                1. Cookies necessários: são cookies essenciais que possibilitam a navegação em nossas aplicações e o acesso a todos os recursos; se estes, nossos serviços podem apresentar mal desempenho ou não funcionar.
                                            </p>
                                            <p className="text-white">
                                                2. Cookies de desempenho: são cookies que otimizam a forma que nossas aplicações funcionam, coletando informações anônimas sobre as páginas acessadas.
                                            </p>
                                            <p className="text-white">
                                                3. Cookies de funcionalidade: são cookies que memorizam suas preferências e escolhas (como seu nome de usuário).
                                            </p>
                                            <p className="text-white">
                                                4. Cookies de publicidade: são cookies que direcionam anúncios em função dos seus interesses e limitam a quantidade de vezes que o anúncio aparece.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                2. Por que usamos Cookies?
                                            </h3>
                                            <p className="text-white">
                                                A CodeScript utiliza Cookies para fornecer a melhor experiência de uso, tornando nossas aplicações mais fáceis e personalizadas, tendo por base suas escolhas e comportamento de navegação.
                                            </p>
                                            <p className="text-white">
                                                Assim, buscamos entender como você utiliza nossas aplicações e ajustar o conteúdo para torná-lo mais relevante para você, além de lembrar de suas preferências.
                                            </p>
                                            <p className="text-white">
                                                Os Cookies participam deste processo porquanto armazenam, leem e executam os dados necessários para cumprir com o nosso objetivo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                3. Que tipo de Cookies utilizamos?
                                            </h3>
                                            <p className="text-white">
                                                Abaixo listamos todos os Cookies que podem ser utilizados pela CodeScript. É importante lembrar que você pode gerenciar a permissão concedida para cada Cookie em seu navegador.
                                            </p>
                                            <h4 className="text-white">
                                                3.1. Cookies necessários
                                            </h4>
                                            <p className="text-white">
                                                Language
                                            </p>
                                            <h4 className="text-white">
                                                3.2. Cookies de funcionalidade
                                            </h4>
                                            <p className="text-white">
                                                Google Analytics
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                4. Gerenciamento dos Cookies
                                            </h3>
                                            <p className="text-white">
                                                A instalação dos cookies está sujeita ao seu consentimento. Apesar da maioria dos navegadores estar inicialmente configurada para aceitar cookies de forma automática, você pode rever suas permissões a qualquer tempo, de forma a bloqueá-los, aceitá-los ou ativar notificações para quando alguns cookies forem enviados ao seu dispositivo.
                                            </p>
                                            <p className="text-white">
                                                Atualmente, na primeira vez que você acessa nossas aplicações, será requerida a sua concordância com a instalação destes. Apenas após a sua aceitação eles serão ativados.
                                            </p>
                                            <p className="text-white">
                                                Para tanto, utilizamos um sistema de (banner de informações ou outro mecanismo que alerta e solicita o consentimento) na página inicial de (nome empresarial). Dessa maneira, não apenas solicitamos sua concordância, mas também informamos que a navegação continuada em nossos sites será entendida como consentimento.
                                            </p>
                                            <p className="text-white">
                                                Como já dito, você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Você também pode configurá-los caso a caso. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
                                            </p>
                                            <p className="text-white">
                                                Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies.
                                            </p>
                                            <p className="text-white">
                                                Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:
                                            </p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Se você usa o Internet Explorer.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s" target="_blank">Se você usa o Firebox.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank">Se você usa o Safari.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR" target="_blank">Se você usa o Google Chrome.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">Se você usa o Microsoft Edge.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Se você usa o Opera.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 pb--50 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                5. Disposições finais
                                            </h3>
                                            <p className="text-white">
                                                Para a CodeScript, a privacidade e confiança são fundamentais para a nossa relação com você. Estamos sempre nos atualizando para manter os mais altos padrões de segurança.
                                            </p>
                                            <p className="text-white">
                                                Assim, reservamo-nos o direito de alterar esta Política de Cookies a qualquer tempo. As mudanças entrarão em vigor logo após a publicação, e você será avisado.
                                            </p>
                                            <p className="text-white">
                                                Ao continuar a navegação nas nossas aplicações após essa mudança se tornar eficaz, você concorda com elas. Aconselhamos que você sempre verifique esta Política, bem como a nossa Política de Privacidade.
                                            </p>
                                            <p className="text-white">
                                                Em caso de dúvidas sobre esta Política de Cookies, entre em contato conosco pelos seguintes meios:
                                            </p>
                                            <p className="text-white">
                                                José Érico C. Soares - Data Protection Officer (DPO) - lgpd@CodeScript.com.br
                                            </p>
                                            <p className="text-white">
                                                Esta Política de Cookies foi atualizada pela última vez: 10/01/2022.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h2 className="text-white">
                                                Cookie Policy
                                            </h2>
                                            <p className="text-white">
                                                The present Cookie Policy is a complementary document to Code Script’s Privacy Policy, available on this link: <a className="link-hover privacy" href="/privacy">Privacy Policy</a>. Here, you’ll find clear and objective information about what are Cookies, what Cookies are utilized within our applications, for what finality they are used and how to config them.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                1. What are Cookies?
                                            </h3>
                                            <p className="text-white">
                                                Cookies are small text files or fragments of information that are downloaded into your computer, smartphone or device with Internet access when you visit our website.
                                            </p>
                                            <p className="text-white">
                                                They contain information about your browsing within our pages and retain only information related to your preferences.
                                            </p>
                                            <p className="text-white">
                                                Thus, this webpage can store and recover data about your browsing habits in order to improve your use experience, for example. It’s important to notice that they do not contain specific personal information, like sensitive or banking data.
                                            </p>
                                            <p className="text-white">
                                                Your browser stores Cookies into the device’s internal storage, but utilizes very minimal space and does not affect the device’s performance. Most of the information are deleted as soon as you finish your browsing session, as you see in the next topic.
                                            </p>
                                            <h4 className="text-white">
                                                1.1 Types of Cookies
                                            </h4>
                                            <p className="text-white">
                                                Regarding their owners, Cookies can be:
                                            </p>
                                            <p className="text-white">
                                                1. Proprietary Cookies: cookies defined by us or by third parties in our name.
                                            </p>
                                            <p className="text-white">
                                                2. Third party Cookies: cookies defined by reliable third parties within our application.
                                            </p>
                                            <p className="text-white">
                                                Regarding their lifespan, Cookies can be:
                                            </p>
                                            <p className="text-white">
                                                1. Session or temporary Cookies: cookies that expires as soon as you close your browser application, closing the session.
                                            </p>
                                            <p className="text-white">
                                                2. Persistent or Permanent Cookies: cookies that remains within your device for a given period or until you manually exclude them.
                                            </p>
                                            <p className="text-white">
                                                Regarding their finality, Cookies can be:
                                            </p>
                                            <p className="text-white">
                                                1. Necessary Cookies: cookies that enable browsing within our applications and the access to all its resources; thus, without them, our services may perform poorly or not even work at all.
                                            </p>
                                            <p className="text-white">
                                                2. Performance Cookies: cookies that optimize the way our application works, collecting anonymous information about the visited pages.
                                            </p>
                                            <p className="text-white">
                                                3. Functionality Cookies: cookies that memorize your configuration preferences and input choices (such as your username, for example).
                                            </p>
                                            <p className="text-white">
                                                4. Publicity Cookies: cookies that directs advertisement regarding your interests and limits the amount of times a given ad appears.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                2. Why do we use Cookies?
                                            </h3>
                                            <p className="text-white">
                                                Code Script utilizes cookies to provide a better user experience, making applications that are easier to use and more customizable, based on user choices and browsing behavior.
                                            </p>
                                            <p className="text-white">
                                                This way, we aim to understand how you utilize our applications and adjust their content to make them more relevant to you, remembering your preferences as well.
                                            </p>
                                            <p className="text-white">
                                                Cookies are part of this process as they store, read and execute the data necessary to achieve this objective.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                3. What kind of Cookies do we utilize?
                                            </h3>
                                            <p className="text-white">
                                                Bellow are listed all the Cookies that can be utilized by Code Script. It’s important to remember that you can manage the consented permissions for each Cookie in your web browser.
                                            </p>
                                            <h4 className="text-white">
                                                3.1. Necessary Cookies
                                            </h4>
                                            <p className="text-white">
                                                Language
                                            </p>
                                            <h4 className="text-white">
                                                3.2. Functionality Cookies
                                            </h4>
                                            <p className="text-white">
                                                Google Analytics
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                4. Managing Cookies
                                            </h3>
                                            <p className="text-white">
                                                The installation of cookies is subjected to your consent. Although most web browsers are initially configured to accept Cookies automatically, you can set your permissions at any given moment, to either block, accept or activate notifications for whenever Cookies are sent to your device.
                                            </p>
                                            <p className="text-white">
                                                Currently, at the first time you access one of our applications, your agreement is required for installing Cookies. Only after your acceptance they will be activated.
                                            </p>
                                            <p className="text-white">
                                                For this purpose, we utilize a notification banner in the homepage. This way, not only do we request your consent we also inform that continuous browsing within our websites will be understood as consent as well.
                                            </p>
                                            <p className="text-white">
                                                As mentioned, you can, at any moment and at no cost at all, block or refuse the use of Cookies. You can also config them in a case-by-case basis. Be aware that revoking consent for certain Cookies may prevent the correct functioning of some of the platform’s features.
                                            </p>
                                            <p className="text-white">
                                                To manage the Cookies of your browser, find the Cookie Managing options within the browser’s settings. You can access tutorials regarding this on the links bellow:
                                            </p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">If you make use of Internet Explorer.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s" target="_blank">If you make use of Firebox.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank">If you make use of Safari.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR" target="_blank">If you make use of Google Chrome.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">If you make use of Microsoft Edge.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">If you make use of Opera.</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 pb--50 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                5. Final dispositions
                                            </h3>
                                            <p className="text-white">
                                                For us at Code Script, privacy and trust are fundamental to the relationship we have with you. We are always improving ourselves to keep the highest standards of security.
                                            </p>
                                            <p className="text-white">
                                                Thus, we reserve ourselves the right to change this Cookie Policy at any given time. Changes will take effect right after publication, and you will be informed.
                                            </p>
                                            <p className="text-white">
                                                By continuing browsing within our applications after these changes takes place, you agree with them. We advise you to always check this Policy, as well as our Privacy Policy.
                                            </p>
                                            <p className="text-white">
                                                Having any doubts about this Cookie Policy, contact us through the following responsible:
                                            </p>
                                            <p className="text-white">
                                                José Érico C. Soares - Data Protection Officer (DPO) - lgpd@CodeScript.com.br
                                            </p>
                                            <p className="text-white">
                                                This Cookie Policy was updated for the last time at: 01/11/2022.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <Footer />

            </Fragment>
        );
    }
}

export default Cookies;
