import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getData } from "../../services/storage";

class BoxIconTwo extends Component{
    constructor() {
        super()
        this.state = {
            language: getData('language')
        }
    }

    render(){
        let Data =[
            {
                iconinner: 'icon-inner',
                icon: 'fa fa-spa',
                title: (this.state.language === false ? 'Agronegócio' : 'Agribusiness'),
                link: '/agro'
            },
            {
                iconinner: 'icon-inner window',
                icon: 'fa fa-truck',
                title: (this.state.language === false ? 'Logística Veicular' : 'Vehicle Logistics'),
                link: '/logistic'
            },
            {
                iconinner: 'icon-inner apple',
                icon: 'fa fa-stethoscope',
                title: (this.state.language === false ? 'Saúde' : 'Health'),
                link: '/medicine'
            },
            {
                iconinner: 'icon-inner java',
                icon: 'fa fa-microchip',
                title: 'IoT',
                link: '/ioT'
            },
            {
                iconinner: 'icon-inner android',
                icon: 'fa fa-link',
                title: 'Web 3.0',
                link: '/web3'
            },
            {
                iconinner: 'icon-inner php',
                icon: 'fa fa-handshake',
                title: 'Outsourcing',
                link: '/outsourcing'
            },

        ]
        let DataList = Data.map((val , i) => {
            return (
                <Col lg="4" md="4" sm="4" xs="4" key={i}>
                    <a href={`${val.link}`}>
                        <div className="box-icon--2">
                            <div className="content">
                                <div className={`${val.iconinner}`}>
                                    <i className={`${val.icon}`}/>
                                </div>
                                <div className="inner">
                                    <h5>{val.title}</h5>
                                </div>
                            </div>
                        </div>
                        </a>
                </Col>
            )
        })
        return(
            <Row className="row--0">
                {DataList}
            </Row>
        )
    }
}
export default BoxIconTwo;
