import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import BoxIconThree from "../../../elements/component/box-icon/BoxIconThree";
import { Animated } from "react-animated-css";
import AOS from 'aos';
import { getData, setData } from "../../../services/storage";

class Outsourcing extends Component {

    state = { language: getData('language') }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
        // or simply just AOS.init();
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="Inove seu negócio introduzindo soluções tecnológicas. Reforçar a sua equipe com os nossos talentos, seja para projetos já em andamento ou na expansão para realizar novos trabalhos."></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>
                <Header />
                <div className="landing-hero-area menu-bg ptb--50">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="mt_sm--30 col-lg-7 col-md-7 col-sm-7 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated
                                        animationIn="bounceInLeft"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        animationInDelay={200}
                                    >
                                        <h3 className="text-white">
                                            {this.state.language === false ? "Para a sua Empresa " : "For your company"}
                                        </h3>
                                        <p className="text-white">
                                            {this.state.language === false 
                                                ? "Inove seu negócio introduzindo soluções tecnológicas. A CodeScript detêm capacidade para fornecer serviços em desenvolvimento de aplicativos, modelagem de sistemas, gerenciamento de equipes e segurança de dados." 
                                                : "Innovate your business introducing technological solutions. CodeScript can provide services on app development, system modeling, team management and data security."
                                            }
                                        </p>
                                    </Animated>
                                    <div className="spacing--1" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated
                                        animationIn="bounceInRight"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        animationInDelay={200}
                                    >
                                        <img
                                            src={require('../../../assets/images/outSourcing/forCompany.svg').default}
                                            alt="Outsourcing"
                                        />
                                    </Animated>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-right" data-aos-duration="500">
                                        <img
                                            src={require('../../../assets/images/outSourcing/forDevelopment.svg').default}
                                            alt="Outsourcing"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-up" data-aos-duration="500">
                                        <h3 className="text-white mt--30">                                            
                                            {this.state.language === false 
                                                ? "Para a sua equipe de Desenvolvimento" 
                                                : "For your development team"
                                            }
                                        </h3>
                                    </div>
                                    <div data-aos="fade-up" data-aos-duration="500">
                                        <p className="text-white">
                                            {this.state.language === false 
                                                ? "Se a sua empresa já trabalha com desenvolvimento de sistemas e de aplicativos, você pode reforçar a sua equipe com os nossos talentos, seja para projetos já em andamento ou na expansão para realizar novos trabalhos." 
                                                : "If your company already works developing systems and applications, you can reinforce your team with our talented developers, whether it be for ongoing projects or to expand for realizing brand new endeavors."
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="gray-background">
                    <div className="container">
                        <div className="row align-items-center pt--100">
                            <div className="mt_sm--30 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="card-blue">
                                    <div className="content">
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h5 className="heading">
                                                {this.state.language === false 
                                                ? "Aplicativos para a Web e Dispositivos Mobile" 
                                                : "Applications for Web and Mobile Devices"
                                                }
                                            </h5>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <p className="card-description" >
                                                {this.state.language === false 
                                                ? "Soluções que ficam à disposição em qualquer lugar e em qualquer tipo de dispositivo." 
                                                : "Solutions that are at your disposal at any place and on any kind of device."
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-4 col-md-4 col-sm-4 col-12 img-justify">
                                <div data-aos="fade-left" data-aos-duration="500">
                                    <img className="img-card-blue" src={require('../../../assets/images/outSourcing/mobile.svg').default} alt="Outsourcing" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center pt--100">
                            <div className="mt_sm--30 col-lg-4 col-md-4 col-sm-4 col-12 img-justify">
                                <div data-aos="fade-right" data-aos-duration="500">
                                    <img className="img-card-blue" src={require('../../../assets/images/outSourcing/system.svg').default} alt="Outsourcing" />
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="card-blue">
                                    <div className="content">
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h5 className="heading">
                                                {this.state.language === false 
                                                ? "Desenvolvimento de Sistemas" 
                                                : "System Development"
                                                }
                                            </h5>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <p className="card-description">
                                                {this.state.language === false 
                                                    ? "Faça a tecnologia trabalhar para você, através de uma infraestrutura e de software rodando em nuvem." 
                                                    : "Make technology work on your behalf, through cloud-based software and infrastructure."
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center pb--100 pt--100">
                            <div className="mt_sm--30 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="card-blue">
                                    <div className="content">
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h5 className="heading">
                                                {this.state.language === false 
                                                    ? "Projetos em ioT" 
                                                    : "ioT Projects"
                                                }
                                            </h5>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <p className="card-description">
                                                {this.state.language === false 
                                                    ? "A integração entre o mundo real e o mundo digital para fazer para a construção de espaços e ambientes inteligentes." 
                                                    : "The integration between the real world and the digital world for constructing intelligent spaces."
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-4 col-md-4 col-sm-4 col-12 img-justify">
                                <div data-aos="fade-left" data-aos-duration="500">
                                    <img className="img-card-blue" src={require('../../../assets/images/outSourcing/iot.svg').default} alt="Outsourcing" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="blue-background">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-up" data-aos-duration="500">
                                        <h3 className="title-justify-center text-white">
                                            {this.state.language === false 
                                                ? "Com esses serviços, desenvolvemos aplicações e adquirimos experiência em diversas áreas ao longo do tempo. Conheça nossas áreas de atuação!" 
                                                : "With these services, we develop applications and have acquired experience in several subjects over time. Get to know our areas of activity!"
                                            }
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="500">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="box-icon pb--120">
                                        <div className="container">
                                            <BoxIconThree language={this.state.language} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Footer Area*/}
                <Footer />
                {/* End Footer Area*/}
            </Fragment>
        );
    }
}

export default Outsourcing;
