import React, { Component } from "react";
import { setData, getData } from "../../services/storage";
import Cookie from 'cookie-universal';
import { Link } from 'react-router-dom';

const cookies = Cookie()

class Footer extends Component {

	state = {
		language: getData('language'),
		preferences: false,
		showPopup: false,
		checked: true,
	}

	componentDidMount() {
		if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }

		const cookieRes = cookies.get('cookie_compliance')

		if (cookieRes === undefined) this.setState({ showPopup: true })
	}

	saveCookies(status) {
		const current = new Date();
		const nextYear = new Date();

		nextYear.setFullYear(current.getFullYear() + 1);

		if (status === 'accept') cookies.set('cookie_compliance', { 'google': true }, { expires: nextYear })
		else if (status === 'refuse') cookies.set('cookie_compliance', { 'google': false }, { expires: nextYear })
		else cookies.set('cookie_compliance', { 'google': this.state.checked }, { expires: nextYear })

		window.location.reload();
	}

	render() {
		return (
			<>
				{this.state.showPopup === true && (
					<div className="row justify-content-center">
						<div className="cookies" id="cookie">
							{this.state.preferences === false ?
								(
									<>
										<div className="row">
											<div className="col">
												<p className="text-blue">
													<b>{this.state.language === false ? 'Aviso' : 'Attention'}</b>
													<br />
													{this.state.language === false ? ' Este site utiliza cookies. Ao continuar navegando neste site, você concorda em utilizar cookies para melhorar sua navegação.'
														: 'This website utilizes cookies in order to provide a better user experience. To continue navigating through it, you must inform whether or not you provide consent for them being used.'}
												</p>
											</div>
										</div>
										<div className="row d-flex justify-content-between mt--15">
											<div className="col mt_sm--10 d-flex">
												<button type="button" className="btn btn-secondary" onClick={() => this.setState({ preferences: true })}>
													{this.state.language === false
														? 'Saber mais e personalizar'
														: 'Know more and personalize'
													}
												</button>
											</div>
											<div className="ml--15 mt_sm--10 d-flex">
												<button type="button" className="mr--10 btn btn-primary" onClick={() => this.saveCookies('refuse')}>
													{this.state.language === false
														? 'Recusar'
														: 'Refuse'
													}
												</button>
												<button type="button" className="btn btn-primary" onClick={() => this.saveCookies('accept')}>
													{this.state.language === false
														? 'Aceitar'
														: 'Accept'
													}
												</button>
											</div>
										</div>
									</>
								)
								: (
									<>
										<div className="row">
											<p className="text-blue">
												<b>
													{this.state.language === false
														? 'Suas preferências de consentimento'
														: 'Your consent preferences'
													}
												</b>
												<br />
												{this.state.language === false
													? 'Cookies são pequenas unidades de dados enviadas por um site e armazenadas no dispositivo do usuário. Eles são utilizados para tornar a experiência do usuário melhor e mais eficiente. Você pode saber um pouco mais sobre cookies, através da Política de Cookies.'
													: 'Cookies are small units of data sent by a website and stored within the user’s device. They are utilized to make a better and more efficient user experience. You may know more about cookies through our Cookie Policy.'
												}
											</p>
											<p className="text-blue">
												{this.state.language === false
													? 'Personalize suas preferências de permissão para qualquer tecnologia de rastreamento usada para nos ajudar a realizar os recursos e atividades descritos abaixo.'
													: 'Personalize your permission preferences for any of the used tracking technologies to aid us in performing the activities e resources bellow described.'
												}
											</p>
										</div>
										<div className="row align-items-center mt--5">
											<div className="custom-control custom-switch swtich-line-height">
												<input checked="true" type="checkbox" className="custom-control-input" disabled id="customSwitch1"
												/>
												<label class="swtich-line-height custom-control-label text-blue" for="customSwitch1">
													{this.state.language === false
														? 'Preferências de idioma (necessário)'
														: 'Language preference (necessary)'
													}
												</label>
											</div>
											<div className="custom-control custom-switch swtich-line-height ml--30 mt--10 ml_sm--0">
												<input checked={this.state.checked} type="checkbox" className="custom-control-input" id="customSwitch2" onChange={() => this.setState({ checked: !this.state.checked })} />
												<label className="custom-control-label text-blue" for="customSwitch2">
													{this.state.language === false
														? 'Recursos do Google'
														: 'Google resources'
													}
												</label>
											</div>
										</div>

										<div className="row align-items-center mt--5">
											<div className="col mt_sm--10 d-flex justify-content-start">
												<button type="button" className="btn btn-secondary" onClick={() => this.setState({ preferences: false })}>
													{this.state.language === false
														? 'Voltar'
														: 'Back'
													}
												</button>
												<Link to="/cookies">
													<button type="button" className="btn btn-info ml--20" >
														{this.state.language === false
															? 'Ver Política de Cookies'
															: 'Cookie Policy'
														}
													</button>
												</Link>
											</div>
											<div className="col mt_sm--10 d-flex justify-content-end">
												<button type="button" className="btn btn-primary" onClick={() => this.saveCookies('save')}>
													{this.state.language === false
														? 'Salvar e aceitar as configurações'
														: 'Save and accept this settings'
													}
												</button>
											</div>
										</div>
									</>
								)}
						</div>
					</div>
				)}

				<footer className="footer-area">
					<div className="footer-top ptb--40">
						<div className="container">
							<div className="row">
								<div className="mt_sm--30 col-lg-3 col-md-3 col-sm-3 col-12">
									<div className="footer-widget mt_mobile--40">
										<h5 className="title">
											{this.state.language === false
												? "Mapa do site"
												: "Sitemap"
											}
										</h5>
										<div className="inner">
											<ul className="menu">
												<li>
													<a className="link-hover" href={`/`}>
														{this.state.language === false
															? "Início"
															: "Home"
														}
													</a>
												</li>
												<li>
													<a className="link-hover" href={`/about`}>
														{this.state.language === false
															? "Sobre"
															: "About"
														}
													</a>
												</li>
												<li>
													<a className="link-hover" href={`/outsourcing`}>
														{this.state.language === false
															? "Atuação"
															: "Work"
														}
													</a>
												</li>
												<li>
													<a className="link-hover" href={`/web3`}>
														Web 3.0
													</a>
												</li>
												<li>
													<a className="link-hover" href={`/career`}>
														{this.state.language === false
															? "Carreira"
															: "Career"
														}
													</a>
												</li>
												<li>
													<a className="link-hover" href={`/contact`}>
														{this.state.language === false
															? "Contato"
															: "Contact"
														}
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="mt_sm--30 col-lg-3 col-md-3 col-sm-3 col-12">
									<div className="footer-widget mt_mobile--40">
										<h5 className="title">
											{this.state.language === false
												? "Entre em contato"
												: "Contact us"
											}
										</h5>
										<div className="inner">
											<div className="row-icon">
												<i className="fas fa-phone fa-rotate-90 icon-footer"></i>
												<p className="text-white">+55 (54) 9 9966-0470</p>
											</div>
											<div className="row-icon">
												<i className="far fa-envelope icon-footer"></i>
												<p className="text-white" style={{ fontSize: 16 }}>
													{this.state.language === false
														? "atendimento@codescript.com.br"
														: "hello@codescript.com.br"
													}
												</p>
											</div>
											<br></br>
											<ul className="social">
												<div className="row-icon">
													<li>
														<a className="link-hover" rel="noreferrer" target="_blank" href={`https://www.instagram.com/codescript.tech/`}>
															<i className="fab fa-instagram"></i>
														</a>
													</li>
													<li>
														<a className="link-hover" rel="noreferrer" target="_blank" href={`https://www.linkedin.com/company/codescript-tech/`}>
															<i className="fab fa-linkedin-in"></i>
														</a>
													</li>
													<li>
														<a className="link-hover" rel="noreferrer" target="_blank" href={`https://www.youtube.com/@code.script`}>
															<i className="fab fa-youtube"></i>
														</a>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
								<div className="mt_sm--30 col-lg-4 col-md-4 col-sm-4 col-12">
									<div className="footer-widget mt_mobile--40">
										<h5 className="title">
											{this.state.language === false
												? "Localização"
												: "Our location"
											}
										</h5>
										<div className="inner">
											<p className="text-white">
												Edifício Privilegium
											</p>
											<p className="text-white">
												{this.state.language === false
													? "R. Quinze de Novembro, 918, Centro, sala 1102, Passo Fundo - RS"
													: "R. Quinze de Novembro, 918, Centro, room 1102, Passo Fundo - RS"
												}
											</p>
										</div>
									</div>
								</div>
								<div className="mt_sm--30 col-lg-2 col-md-2 col-sm-2 col-12 ">
									<div className="footer-widget mt_mobile--40">
										<h5 className="title">
											{this.state.language === false
												? "Aponte a câmera"
												: "Point your camera"
											}
										</h5>
										<div className="inner">
											<img
												className="qrCode"
												src={require('../../assets/images/preview/qr-code.svg').default}
												alt="CodeScript"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="copyright-area copyright-style--1">
							<div className="row align-items-center">
								<div className="mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
									<div className="copyright text-center text-md-left">
										<p className="text-white">
											{this.state.language === false
												? `${`© CodeScript Tecnologia ${new Date().getFullYear()}`}`
												: `${`© CodeScript Technology ${new Date().getFullYear()}`}`
											}
										</p>
									</div>
								</div>
								<div className="mt_sm--30 col-lg-2 col-md-2 col-sm-2 col-12">
									<a href="/">
										<img
											src={require('../../assets/images/logo/logo_codescript2.png').default}
											alt="CodeScript"
										/>
									</a>
								</div>

								<div className="mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
									<div className="copyright-right text-center text-md-right">
										<a className="link-hover pointer" 
											onClick={() => { 
												this.setState({ showPopup: true })
											}}>
											<p className="text-footer privacy">
												{this.state.language === false
													? "Política de Cookies"
													: "Cookies Policy"
												}
											</p>
										</a>
										<br />
										<a className="link-hover" href='/privacy'>
											<p className="text-footer privacy">
												{this.state.language === false
													? "Termos e Políticas de Privacidade"
													: "Privacy Policy"
												}
											</p>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}

export default Footer;
