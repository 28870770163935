import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import BoxIconSeven from "../../../elements/component/box-icon/BoxIconSeven";
import AOS from 'aos';
import { getData, setData } from "../../../services/storage";

class ioT extends Component {

    state = { language: getData('language') }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
        // or simply just AOS.init();
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

        this.animation()
    }

    animation = () => {
        var TWO_PI = Math.PI * 2;
        var HALF_PI = Math.PI / 2;
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var scale = window.devicePixelRatio || 1;
        var lines = [];
        var frame = 0;
        var width;
        var height;
        var gradient;

        function Line(x, y) {
            this.x = x;
            this.y = y;
            this.path = [];
            this.pathLength = 0;
            this.angle = 0;
            this.speed = random(1, 4);
            this.target = { x: x + 0.1, y: y + 0.1 };
            this.thickness = Math.round(random(0.5, 3));
            this.maxLength = Math.round(random(100, 350));
            this.hasShadow = this.thickness > 2;
            this.decay = random(0.0075, 0.05);
            this.alpha = 1;
        }

        Line.prototype.step = function () {

            if (this.pathLength >= this.maxLength) {
                this.alpha -= this.decay;
                return;
            }

            this.x += Math.cos(this.angle) * this.speed;
            this.y += Math.sin(this.angle) * this.speed;

            var isAnchor = false;
            var target = this.target;
            var dx = target.x - this.x;
            var dy = target.y - this.y;
            var distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < this.speed) {
                isAnchor = true;
                this.x = target.x;
                this.y = target.y;
                this.steer();
            }

            this.path.push({
                x: this.x,
                y: this.y,
                isAnchor: isAnchor
            });

            this.pathLength++;
        };

        Line.prototype.draw = function () {

            context.save();
            context.globalAlpha = this.alpha;
            context.lineWidth = this.thickness;

            context.beginPath();

            if (this.hasShadow) {
                context.shadowOffsetX = 10;
                context.shadowOffsetY = 20;
                context.shadowBlur = 12;
                context.shadowColor = 'rgba(0,0,0,0.09)';
            }

            this.path.forEach(function (point, i) {
                context[i === 0 ? 'moveTo' : 'lineTo'](point.x, point.y);
            });

            context.stroke();

            context.beginPath();
            context.arc(this.path[0].x, this.path[0].y, 4, 0, TWO_PI);
            context.fill();
            context.stroke();
            context.restore();
        };

        Line.prototype.steer = function () {

            var distance = random(50, 200);
            var angle = random([-HALF_PI, 0, HALF_PI, -Math.PI]);

            // squash all non-anchor points to squeeze out some extra performance
            this.path = this.path.filter(function (point) { return point.isAnchor === true; });

            this.target.x = this.x + Math.cos(angle) * distance;
            this.target.y = this.y + Math.sin(angle) * distance;
            this.angle = angle;
        };


        function random(min, max) {
            if (arguments.length === 0) return Math.random();
            if (Array.isArray(min)) return min[Math.floor(Math.random() * min.length)];
            if (typeof min === 'undefined') min = 1;
            if (typeof max === 'undefined') max = 1;
            return min + Math.random() * (max - min);
        }

        const draw = (time) => {

            context.clearRect(0, 0, canvas.width, canvas.height);
            context.lineCap = 'round';
            context.strokeStyle = gradient;
            context.fillStyle = '#F7FAFB';

            lines = lines.filter(function (line) {
                line.step();
                return line.alpha > 0.01;
            });

            lines.forEach(function (line) {
                line.draw();
            });

            if (frame % 12 === 0) {
                var x = width * 0.5 + random(-150, 150);
                var y = height * 0.5 + random(-100, 100);
                lines.push(new Line(x, y));
            }

            frame++;
            draw.raf = requestAnimationFrame(draw);
        }

        function resize(event) {
            width = window.screen.width < 1024 || window.screen.height < 768 ? 0 : window.innerWidth;
            height = window.screen.width < 1024 || window.screen.height < 768 ? 0 : window.innerHeight;
            canvas.width = width * scale;
            canvas.height = height * scale;
            context.scale(scale, scale);
            gradient = context.createLinearGradient(width * 0.25, 0, width * 0.75, 0);
            gradient.addColorStop(1, '#0285f4');
            gradient.addColorStop(0, '#0de9c4');
        }

        canvas.style.cssText = 'width:100%;height:100%;top:0;left:0;';
        document.documentElement.style.cssText = 'margin:0;padding:0;height:100%';
        document.getElementById("animation").style.cssText = 'height:100%;padding:0;overflow:hidden;';
        document.getElementById("animation").appendChild(canvas);

        window.addEventListener('resize', resize);
        resize();
        requestAnimationFrame(draw);

    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="IoT é uma sigla que no idioma inglês se refere ao termo Internet of Things, que em português se traduz como Internet das Coisas."></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>
                <Header />
                <div className="bg-iot">
                    <div className="container">
                        <h2 className="title-banner">
                            {this.state.language === false
                                ? "Interligando o mundo real com o mundo digital."
                                : "Interconnecting the real world with the digital world."
                            }
                        </h2>
                    </div>
                </div>
                <div className="gray-background pt--20">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false
                                    ? "Internet das Coisas"
                                    : "Solutions"
                                }
                            </h2>
                        </div>
                        <div className="row align-items-center pt--30">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div data-aos="fade-right" data-aos-duration="500">
                                        <div className="border-left-img">
                                            <img className="img-services" src={require('../../../assets/images/ioT/ioT.jpg').default} alt="iot" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div className="tab-inner history-inner">
                                        <div className="inner">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "IoT é uma sigla que no idioma inglês se refere ao termo Internet of Things, que em português se traduz como Internet das Coisas."
                                                        : "Often mentioned using the acronym IoT, this refers to the concept of connecting all sorts of home appliances, equipment or furniture to the Internet and making these objects communicate between one another and between traditional computational devices."
                                                    }
                                                    <br />
                                                    {this.state.language === false
                                                        ? "Isso refere-se ao conceito de conectar à Internet todo tipo de eletrodoméstico, equipamento ou mobília de um ambiente e fazer estes objetos se comunicarem entre si e com dispositivos computacionais tradicionais. "
                                                        : ""
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background pt--20">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false
                                    ? "Tecnologia"
                                    : "Technology"
                                }
                            </h2>
                        </div>
                        <div className="row align-items-center">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div className="tab-inner history-inner">
                                        <div className="inner">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "Este conceito é uma tendência que está se tornando cada vez mais proeminente, visto as oportunidades e facilidades existentes em se trazer todo o tipo de objetos de nosso cotidiano para dentro da Internet e fazê-los compartilhar dados entre si e com os sistemas e aplicativos que já habitam o espaço digital."
                                                        : "This concept is becoming ever more prominent, given the opportunities and facilities present in bringing all sorts of everyday equipment into the Internet and making them share date among themselves and with systems and applications already existing in the digital space."
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-left" data-aos-duration="500">
                                        <div className="border-right-img">
                                            <img className="img-services" src={require('../../../assets/images/ioT/iotTech.jpg').default} alt="iot" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background pb--50">
                    <div className="container">
                        <div id="animation">
                            <div>
                                <div data-aos="fade-up" data-aos-duration="500">
                                    <h2 className="title-justify-center">
                                        {this.state.language === false
                                            ? "Soluções"
                                            : "Solutions"
                                        }
                                    </h2>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                        <div className="tab-inner history-inner">
                                            <div className="inner">
                                                <div data-aos="fade-up" data-aos-duration="500">
                                                    <h4 className="text-blue" style={{ padding: 20 }}>
                                                        {this.state.language === false
                                                            ? "Através do uso de sensores, equipamentos e placas com os quais é feita uma interface via aplicativo é possível"
                                                            : "Using sensors, equipment and electronic boards that are interfaced through apps it is possible for us to "
                                                        }
                                                        {this.state.language === false
                                                            ? "criar soluções de IoT dentro de nossas áreas de atuação."
                                                            : "create IoT solutions within any of our areas of activity."
                                                        }
                                                    </h4>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div data-aos="fade-up" data-aos-duration="500">
                                    <BoxIconSeven language={this.state.language} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blue-background pb--80 pt--40">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <div className="row">
                                <div className="col">
                                    <h3 className="text-investment text-white">
                                        {this.state.language === false
                                            ? "Conheça um pouco mais sobre:"
                                            : "Get to know more about:"
                                        }
                                    </h3>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="/medicine">
                                        <div className="demo_wrap">
                                            <h3>
                                                {this.state.language === false
                                                    ? "Saúde"
                                                    : "Health"
                                                }
                                            </h3>
                                            <img src={require(`../../../assets/images/boxIcon/medicine.jpg`).default} alt="Logistica Veicular" />
                                        </div>
                                    </a>
                                </div>
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="/logistic">
                                        <div className="demo_wrap">
                                            <h3>
                                                
                                                {this.state.language === false
                                                    ? "Logística Veicular"
                                                    : "Vehicle Logistics"
                                                }
                                            </h3>
                                            <img src={require(`../../../assets/images/boxIcon/logistic.jpg`).default} alt="iot" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}

export default ioT;
