import React, { Component } from "react";
import { Animated } from "react-animated-css";
import { getData, setData } from "../../services/storage";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

class ContactOne extends Component {

    state = {
        language: getData('language'),
        name: '',
        phone: '',
        subject: '',
        email: '',
        message: '',
    }

    componentDidMount() {
        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
    }

    sendMail() {
        if(
            this.state.name === '' ||
            this.state.email === '' ||
            this.state.subject === '' ||
            this.state.message === ''
        ) {
            alert(this.state.language === false ? "Por favor, informe todos os campos obrigatórios." : "Please, enter all required fields")
        }
        else {
           
            const recaptchaValue = recaptchaRef.current.getValue();

            axios.post('mail/contact.php', {
                name: this.state.name,
                phone: this.state.phone,
                email: this.state.email,
                subject: this.state.subject,
                message: this.state.message,
                tokenRecaptcha: recaptchaValue,
                language: this.state.language,
                headers: {
                    "Content-Type": 'application/json',
                }
            },
            { timeout: 8000 }
            )
                .then(response => {
                    alert(`${response.data.message}`); 
                    this.setState({ 
                        name: '',   
                        phone: '',
                        subject: '',
                        email: '',
                        message: '',
                    })
                    recaptchaRef.current.reset();
                 })
        }
    }

    handleFormSubmit(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div className="landing-hero-area menu-bg pb--50 ">
                <div className="contact-form-wrapper contact-form-one">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="mt_sm--30 col-lg-7 col-md-7 col-sm-7 col-12">
                                <div className="contact-form-inner">
                                    <div className="section-title mb--20 mt--50">
                                        <h2 className="mt--20 text-white">{this.state.language === false ? 'Entre em contato conosco' : 'Contact us'}</h2>
                                    </div>
                                    <form className="contact-form--1">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label htmlFor="name"></label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    required
                                                    placeholder={this.state.language === false ? "Nome *" : "Name *"}
                                                    value={this.state.name}
                                                    onChange={e => this.setState({ name: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="phone"></label>
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    id="phone"
                                                    placeholder={this.state.language === false ? "Telefone" : "Phone"}
                                                    value={this.state.phone}
                                                    onChange={e => this.setState({ phone: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label htmlFor="email"></label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email *"
                                                    value={this.state.email}
                                                    onChange={e => this.setState({ email: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label htmlFor="subject"></label>
                                                <input
                                                    type="subject"
                                                    name="subject"
                                                    id="subject"
                                                    placeholder={this.state.language === false ? "Assunto *" : "Subject *"}
                                                    value={this.state.subject}
                                                    onChange={e => this.setState({ subject: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label htmlFor="message"></label>
                                                <textarea
                                                    type="message"
                                                    name="message"
                                                    id="message"
                                                    placeholder={this.state.language === false ? "Mensagem *" : "Message *"}
                                                    value={this.state.message}
                                                    onChange={e => this.setState({ message: e.target.value })}
                                                    rows="4"
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="text-white mb--20">
                                            * Campos obrigatórios.
                                        </div>
                                        <div className="row align-items-center mb--10">
                                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                                <ReCAPTCHA
                                                    sitekey="6Lf_SLgdAAAAABWHLrCxgKxrd_97HUx1qfo7HLuH"
                                                    ref={recaptchaRef}
                                                />
                                            </div>
                                            <div className="ml--10 mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
                                                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1250}>
                                                    <div>
                                                        <button onClick={() => this.sendMail()} className="ar-button style-flat" type="button">
                                                            <span className="button-text">{this.state.language === false ? 'ENVIAR MENSAGEM' : 'SEND MESSAGE'}</span>
                                                        </button>
                                                    </div>
                                                </Animated>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated
                                        animationIn="bounceInRight"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        animationInDelay={200}
                                    >
                                        <img src={require('../../assets/images/preview/contact.svg').default} alt="CodeScript" />
                                    </Animated>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactOne;
