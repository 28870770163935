import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BoxIconSeven extends Component {
    render() {
        return (
            <Row>
                <Col style={{ marginTop: 30 }} lg="4" md="6" sm="12" xs="12">
                    <a href="/medicine">
                        <div className="box-icon--7">
                            <div className="svg-inner">
                                <img src={require('../../../assets/images/ioT/iot-saude.svg').default} alt="app landing" />
                            </div>
                            <div className="content">
                                <h5 className="heading">
                                    {this.props.language === false
                                        ? "Saúde"
                                        : "Health"
                                    }
                                </h5>
                                <p>
                                    {this.props.language === false
                                        ? "Tecnologia para o bem-estar"
                                        : "Technology for well-being"
                                    }
                                </p>
                            </div>
                        </div>
                    </a>
                </Col>
                <Col style={{ marginTop: 30 }} lg="4" md="6" sm="12" xs="12">
                    <a href="/agro">
                        <div className="box-icon--7">
                            <div className="svg-inner">
                                <img src={require('../../../assets/images/ioT/iot-agricultura.svg').default} alt="app landing" />
                            </div>
                            <div className="content">
                                <h5 className="heading">
                                    {this.props.language === false
                                        ? "Agronegócio"
                                        : "Agribusiness"
                                    }
                                </h5>
                                <p>
                                    {this.props.language === false
                                        ? "Agregando valor ao Agronegócio"
                                        : "Bringing value to Agribusiness"
                                    }
                                </p>
                            </div>
                        </div>
                    </a>
                </Col>
                <Col style={{ marginTop: 30 }} lg="4" md="6" sm="12" xs="12">
                    <a href="/logistic">
                        <div className="box-icon--7">
                            <div className="svg-inner">
                                <img src={require('../../../assets/images/ioT/iot-logistica.svg').default} alt="app landing" />
                            </div>
                            <div className="content">
                                <h5 className="heading">
                                    {this.props.language === false
                                        ? "Logística Veicular"
                                        : "Vehicle Logistics"
                                    }
                                </h5>
                                <p>
                                    
                                    {this.props.language === false
                                        ? "Traçando a rota para o seu negócio"
                                        : "Charting routes for your business"
                                    }
                                </p>
                            </div>
                        </div>
                    </a>
                </Col>
            </Row>
        )
    }
}

export default BoxIconSeven;