import React, { Component } from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";


class GalleryOne extends Component{
    render(){
        const sectionTitle = this.props.language === false ? "Nossa Casa" : "Our Home";
        const sectionDescription = this.props.language === false ? "Apaixonados pela tecnologia, buscamos realizar os sonhos dos nossos clientes" : "With great passion for technology, we aim to bring the dreams of our clients into reality";
        
        const Gallery = [
            "g01.svg",
            "g02.svg",
            "g03.svg",
            "g04.svg",
            "g05.svg",
            "g06.svg",
            "g07.svg",
            "g08.svg",
            "g09.svg",
        ];
        
        const PhotoItem = ({ image, group }) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                <div className="gallery">
                    <LightgalleryItem group={group} src={require(`../../assets/images/gallery/${image}`).default}>
                        <img className="w-100" src={require(`../../assets/images/gallery/${image}`).default} alt="CodeScript Gallery" />
                        <div className="action">
                            <i className="fa fa-search"></i>
                        </div>
                        <div className="overlay"></div>
                    </LightgalleryItem>
                </div>
            </div>
        );

        return(
            <div>
                <LightgalleryProvider>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title mb--10 mb_sm--10">
                                <div data-aos="fade-up" data-aos-duration="500">
                                    <h2 className="text-white">{sectionTitle}</h2>
                                </div>
                                <div data-aos="fade-up" data-aos-duration="500">
                                    <p className="text-white text-justify mb--50">{sectionDescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" data-aos-duration="500">
                        <div className="row">
                            {Gallery.map((p, idx) => (
                                <PhotoItem key={idx} image={p} />
                            ))}
                        </div>
                    </div>
                   
                </LightgalleryProvider>
            </div>
        )
    }
}
export default GalleryOne;
