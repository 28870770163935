import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class Iconbox extends Component{
    render(){
        let Data = [
            {
                icon: 'fa-lightbulb',
                title: this.props.language === false ? 'Defina suas ideias' : 'Define your ideas',
                desc: this.props.language === false 
                    ? 'Apresente as ideias de como você imagina que um aplicativo possa se comportar para alcançar seus objetivos.'
                    : 'Present your ideas of how do you imagine an application could function to achieve your objectives'
            },
            {
                icon: 'fa-paint-brush',
                title: this.props.language === false ? 'Trabalhando com Design' : 'Working with design',
                desc: this.props.language === false 
                    ? 'Nossos especialistas trabalham na modelagem de dados e no design de Interface e de Experiência de suas ideias.'
                    : 'Our specialists work on data modelling and on Interface and Experience design of your idea.'
            },
            {
                icon: 'fa-laptop-code',
                title: this.props.language === false ? 'Programação e Revisão' : 'Coding and Evaluation',
                desc: this.props.language === false 
                    ? 'O produto final é desenvolvido e é meticulosamente validado em conjunto com todas as partes.'
                    : 'The final product is developed and is meticulously validated alongside all the involved parties.'
            }
        ]
        let DataList = Data.map((val , i) => {
            return(
                <Col className="single-icon-box mt_sm--30" lg="4" md="4" sm="6" xs="12" key={i}>
                    <div className="icon-box">
                        <div className="line">
                            <div className="dot dot-1"></div>
                            <div className="dot dot-2"></div>
                            <div className="dot dot-3"></div>
                            <div className="dot dot-4"></div>
                            <div className="dot dot-5"></div>
                            <div className="dot dot-4"></div>
                            <div className="dot dot-3"></div>
                            <div className="dot dot-2"></div>
                            <div className="dot dot-1"></div>
                        </div>
                        <div className="content">
                            <div className="icon">
                                <i className= {`fa ${val.icon}`}></i>
                            </div>
                            <h5 className="codeScript-text">{val.title}</h5>
                            <p className="text-white">{val.desc}</p>
                        </div>
                    </div>
                </Col>
            )
        })
        return(
            <Container>
                <Row className="mt_dec--30">
                    {DataList}
                </Row>
            </Container>
        )
    }
}
export default Iconbox