import { Helmet } from "react-helmet";
import React, { Component, Fragment } from "react";
import Footer from '../../components/footer/Footer';
import Header from "../../components/header/Header";
import ContactOne from '../../components/contact/ContactOne';

class Contact extends Component {

    componentDidMount() {
        document.documentElement.scrollTop = 0
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="Entre em contato conosco!"></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>

                <Header />

                <ContactOne />

                <Footer />
            </Fragment>
        );
    }
}

export default Contact;
