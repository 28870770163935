import React, { Component } from "react";


class ListOne extends Component{
    render(){
        const namesPT = [
            'Agropecuária',
            'Indústrias de tecnologias e de transformação das matérias-primas',
            'Transporte e comercialização dos produtos derivados do campo',
        ];
        const namesEN = [
            'Farming',
            'Industries of raw material technology and transformation',
            'Transport and selling of crop derived products',
        ];

        return(
            <ul className="list-style--1">
                {this.props.language === false
                ?
                    namesPT.map((name, index) => {
                        return <li key={ index }><i className="fa fa-check"></i> {name}</li>;
                    }) 
                :
                    namesEN.map((name, index) => {
                        return <li key={ index }><i className="fa fa-check"></i> {name}</li>;
                    }) 
                }
            </ul>
        )
    }
}
export default ListOne;