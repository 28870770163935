import { Helmet } from "react-helmet";
import React, { useEffect, useState, Fragment } from "react";
import Footer from '../../components/footer/Footer';
import Header from "../../components/header/Header";
import { getData } from "../../services/storage";
import Iframe from 'react-iframe'

import AOS from 'aos';

function Game() {

    const [language] = useState(getData('language'));

    useEffect(() => {
        document.documentElement.scrollTop = 0
    }, []);

    AOS.init({
        duration: 2000
    });

    return (
        <Fragment>
            <Helmet>
                <title>CodeScript</title>
                <meta name="description" content="Entre em contato conosco!"></meta>
                <meta name="keywords" content="contato, projetos, ideias, desenvolvimento, aplicações, app, aplicativos" />
            </Helmet>

            <Header />

            <div className="landing-hero-area menu-bg">
                <div className="landing-hero-inner pt--50 pt_md--50 pt_sm--0">
                    <div data-aos="fade-right" data-aos-duration="500">
                        <br />
                        <h2 className="text-white text-center">
                            CodeScript Quest
                        </h2>
                        <br />
                        <h4 className="text-white text-center">
                            {language === false
                                ? 'Junte-se à equipe da CodeScript em uma fantástica aventura dentro do Metaverso!'
                                : 'Join the CodeScript team on a fantastic adventure into the Metaverse!'
                            }
                        </h4>
                        <h5 className="text-white text-center">
                            {language === false
                                ? 'Mais fases serão adicionadas em breve! Acompanhe nossas redes socias pra novidades!'
                                : 'More stages will be added soon! Follow our social networks for news!'
                            }
                        </h5>
                        <br />
                    </div>
                    <div data-aos="fade-left" data-aos-duration="500">
                        <div className="container">
                            <div className="text-center">
                                <Iframe
                                    src="/CodeScript_v1.0.0/index.html"
                                    width="1000px"
                                    height="800px"
                                    frameBorder='0'
                                />
                            </div>
                            <div style={{ paddingBottom: 50, paddingLeft: 100 }}>
                                <div>
                                    <h4 className="text-white">
                                        {language === false
                                            ? 'Controles:'
                                            : 'Controls:'
                                        }
                                    </h4>
                                </div>
                                <div className="submenu">
                                    <div style={{ paddingLeft: 20 }}>
                                        <div className="row">
                                            <button className='controls arrows'> ← </button>
                                            <button className='controls arrows'> ↑ </button>
                                            <button className='controls arrows'> ↓ </button>
                                            <button className='controls arrows'> → </button>
                                            <p className="controls-description">
                                                {language === false ? 'Pressione para movimentar o personagem.' : 'Press to move the character.'}
                                            </p>
                                        </div>
                                        <div className="row">
                                            <button className="controls space">
                                                <span>{language === false ? 'ESPAÇO' : 'SPACE'}</span>
                                            </button>
                                            <p className="controls-description">
                                                {language === false ? 'Pressione para pular.' : 'Press to jump.'}
                                            </p>
                                        </div>
                                        <div className="row">
                                            <button className="controls shift"> SHIFT </button>
                                            <p className="controls-description">
                                                {language === false ? 'Segure para correr.' : 'Hold to run.'}
                                            </p>
                                        </div>
                                        <div className="row">
                                            <button className="controls ctrl"> CTRL </button>
                                            <p className="controls-description">
                                                {language === false ? 'Pressione para ativar a mira.' : 'Press to activate crosshairs.'}
                                            </p>
                                        </div>
                                        <div className="row">
                                            <button className="controls key"> A </button>
                                            <p className="controls-description">
                                                {language === false ? 'Pressione para atirar.' : 'Press to shoot.'}
                                            </p>
                                        </div>
                                        <div className="row">
                                            <button className="controls key"> S </button>
                                            <p className="controls-description">
                                                {language === false ? 'Pressione para interagir com objetos.' : 'Press to interact with objects.'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment >
    );
}

export default Game;
