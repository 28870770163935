import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer/Footer';
import GalleryOne from '../../components/gallery/GalleryOne';
import Header from '../../components/header/Header';
import TabsOne from "../../elements/component/tabs/TabsOne";
import ContactOne from "../../components/contact/ContactOne";
import { Animated } from "react-animated-css";
import AOS from 'aos';
import { getData, setData } from '../../services/storage/index';

class About extends Component {

    state = {
        thePosition: 0,
        showAnimation: false,
        language: getData('language'),
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        window.addEventListener('scroll', this.listenToScroll)

        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }

        AOS.init({
            // initialise with other settings
            duration: 2000
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight - document.documentElement.clientHeight

        const scrolled = winScroll / height

        this.setState({
            thePosition: scrolled,
        })

        if (this.state.thePosition > 0.8300000) this.setState({ showAnimation: true })
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                    <meta name="description" content="CodeScript é uma empresa inovadora com uma equipe capacitada e com formações diversificadas dentro da área da tecnologia da informação."></meta>
                    <script src="https://www.google.com/recaptcha/api.js" defer ></script>
                </Helmet>
                <Header />
                <div className="landing-hero-area menu-bg">
                    <div className="container">
                        <div className="row">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated
                                        animationIn="bounceInLeft"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        animationInDelay={200}
                                    >
                                        <h2 className="text-white">
                                            {this.state.language === false ? 'Quem somos? ' : 'About us'}
                                        </h2>
                                        <p className="text-white">
                                            {this.state.language === false 
                                                ? 'Somos uma empresa inovadora com uma equipe capacitada e com formações diversificadas dentro da área da tecnologia da informação. Há quase uma década, buscamos transformar os sonhos dos nossos clientes em realidade. Somos profissionais que gostam de aceitar desafios e transformar ideias em conquistas através da tecnologia, utilizando das mais diversas linguagens e metodologias.' 
                                                : 'We are an innovative company featuring a capacitated team with a diversity of qualifications within the Information Technology area. For almost a decade, we long for making into reality the dreams of our clients. We’re professionals that enjoy taking in challenges and transforming ideas into achievements through technology, making use of all sorts of programing languages and development methodologies.'
                                            }
                                        </p>
                                    </Animated>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated
                                        animationIn="bounceInRight"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        animationInDelay={200}
                                    >
                                        <img
                                            src={require('../../assets/images/about/whoWeAre.svg').default}
                                            alt={this.state.language === false
                                                    ? 'Quem somos nós'
                                                    : 'Who we are'
                                                }
                                        />
                                    </Animated>
                                </div>
                            </div>
                        </div>
                        <div className="tabs-area ptb--80">
                            <div data-aos="fade-up" data-aos-duration="500">
                                <TabsOne language={this.state.language} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background">
                    <div className="container">
                        <div className="row">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-right" data-aos-duration="500">
                                        <img
                                            src={require('../../assets/images/about/whatWeDo.svg').default}
                                            alt={this.state.language === false
                                                ? 'O que fazemos'
                                                : 'What we do'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner ptb--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-up" data-aos-duration="500">
                                        <h2 className="text-blue">
                                            
                                            {this.state.language === false 
                                                ? 'O que fazemos ?' 
                                                : 'What we do ?'
                                            }
                                        </h2>
                                    </div>
                                    <div data-aos="fade-up" data-aos-duration="500">
                                        <p className="text-blue">
                                            {this.state.language === false 
                                                ? 'Atuamos nas áreas do agronegócio, logística para gestão de frotas, internet das coisas e da saúde. Desenvolvemos aplicações móveis, softwares específicos de acordo com a regra de negócio do cliente, aplicações com interação de sistemas embarcados e criamos scripts para ioTs e automação. A CodeScript também tem como serviço o conceito de Outsourcing, na qual sua empresa pode terceirizar diversos trabalhos da área da tecnologia, entre eles, desenvolvimento de softwares específicos, suporte a programas existentes, gerenciamento de equipes, entre outros.' 
                                                : 'We’re active in the areas of agribusiness, logistics for route management, Internet of Things and health. We develop mobile applications, specific software solutions according to client’s business rule, applications that interacts with embedded systems, and the creation of scripts for IoT and automation. CodeScript also offers Outsourcing as a service, being capable of providing to your company services within several fields of technology, such as: software development, support for existing programs, team management, among other activities.'
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="galery-area ptb--50 thine-line blue-background">
                    <div className="container">
                        <GalleryOne language={this.state.language} />
                    </div>
                </div>
                <div className="gray-background">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-5 col-12 location-background">
                        </div>
                        <div className="mt_sm--30 col-lg-7 col-md-7 col-sm-7 col-12 location-map">
                            <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                <div data-aos="fade-up" data-aos-duration="500">
                                    <h2 className="text-blue" style={{ marginLeft: 10 }}>
                                       
                                        {this.state.language === false 
                                            ? 'Onde estamos localizados?' 
                                            : 'Where are we located?'
                                        }
                                    </h2>
                                    <div>
                                        <iframe title="myFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2956.073706656207!2d-52.388775238048524!3d-28.226025772140535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfcf587ba10352cbe!2sStart%20Tecnologia%20Web!5e0!3m2!1spt-BR!2sbr!4v1637257684003!5m2!1spt-BR!2sbr"
                                            style={{ borderWidth: 0, width: '100%', height: 330, boxShadow: 10 }} loading="lazy"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: -120 }}>
                    <ContactOne />
                </div>
                <Footer />
            </div>
        )
    }
}

export default About;