import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsOne extends Component {
    render() {
        let
            tab1 = this.props.language === false ? "Missão" : "Mission",
            tab2 = this.props.language === false ? "Visão" : "Vision",
            tab3 = this.props.language === false ? "Valores" : "Values";

        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Tabs>
                                    <TabList className="tab-style--1">
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="row align-items-center">
                                            <div className="mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
                                                <img
                                                    src={require('../../../assets/images/about/mission.svg').default}
                                                    alt="App Landing"
                                                />
                                            </div>
                                            <div className="mt_sm--30 col-lg-7 col-md-7 col-sm-7 col-12">
                                                <div className="tab-inner history-inner">
                                                    <div className="inner">
                                                        <h4 className="text-white text-justify">
                                                            {this.props.language === false
                                                                ? 'Temos por missão desenvolver e melhorar processos e sistemas tecnológicos de nossos clientes ligando isto a evolução de nossos colaboradores e parceiros.'
                                                                : 'Our mission is to develop and improve processes and computational systems of our clients, providing with it the evolution of our collaborators and colleagues.'
                                                            }
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="row align-items-center">
                                            <div className="mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
                                                <img
                                                    src={require('../../../assets/images/about/vision.svg').default}
                                                    alt="App Landing"
                                                />
                                            </div>
                                            <div className="mt_sm--30 col-lg-7 col-md-7 col-sm-7 col-12">
                                                <div className="tab-inner history-inner">
                                                    <div className="inner">
                                                        <h4 className="text-white text-justify">
                                                            {this.props.language === false
                                                                ? 'A CodeScript almeja tornar-se uma referência tecnológica tanto para clientes quanto colaboradores até o ano de 2025.'
                                                                : 'CodeScript aims to become a reference of technology for both our clients as well as our collaborators until the year 2025.'
                                                            }
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="row align-items-center">
                                            <div className="mt_sm--30 col-lg-5 col-md-5 col-sm-5 col-12">
                                                <img
                                                    src={require('../../../assets/images/about/values.svg').default}
                                                    alt="App Landing"
                                                />
                                            </div>
                                            <div className="mt_sm--30 col-lg-7 col-md-7 col-sm-7 col-12">
                                                <div className="tab-inner history-inner">
                                                    <div className="inner">
                                                        <h4 className="text-white text-justify">
                                                            {this.props.language === false
                                                                ? 'Acreditamos que como seres humanos todos somos falhos e possuímos defeitos, porém existem valores que em nenhum momento são esquecidos em nossa empresa como honestidade, respeito, humanidade, liberdade e responsabilidade.'
                                                                : 'We believe that, as we are human beings, we all are faulty and have defects, yet there are values that at no time are forgotten inside of our company such as honesty, respect, humbleness, freedom and responsibility.'
                                                            }
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}

export default TabsOne;