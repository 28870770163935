import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import { Animated } from "react-animated-css";
import BoxIconNine from '../../../elements/component/box-icon/BoxIconNine';
import ListOne from '../../../elements/component/list/ListOne';
import AOS from 'aos';
import { getData, setData } from "../../../services/storage";

class Agro extends Component {

    state = {
        thePosition: 0,
        showAnimation: false,
        language: getData('language')
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
        // or simply just AOS.init();
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight - document.documentElement.clientHeight

        const scrolled = winScroll / height

        this.setState({
            thePosition: scrolled,
        })

        if (this.state.thePosition > 0.5000) {
            if (window.screen.width < 640 || window.screen.height < 480) {
                this.setState({ showAnimation: false })
            } else if (window.screen.width < 1024 || window.screen.height < 768) {
                this.setState({ showAnimation: false })
            } else {
                this.setState({ showAnimation: true })
                this.tractorAnimation()
            }
        }
    }

    tractorAnimation = () => {
      /*  let tractor = document.getElementById('body-tractor');
        let wheel = document.getElementsByClassName('wheel')[0];
        let smallWheel = document.getElementsByClassName('wheel-small')[0];
        let smoke = document.getElementsByClassName('s');
        let cards = document.getElementsByClassName('cardTractor');

        var eventOnAnimate = new Event('onanimate');
        var time = 0;

        tractor.addEventListener('animationend', function (e) {
            //faz a roda grand eparar de girar
            wheel.classList.add('paused-animation');
            //faz a roda pequena parar de girar
            smallWheel.classList.add('paused-animation');
            //stop smoke animation
            for (var i = 0; i < smoke.length; i++) {
                smoke[i].classList.add('paused-animation');
                smoke[i].style.display = 'none';
            }
            clearInterval(tractor.interval);
            time = 0;
        });

        tractor.addEventListener('animationstart', function (e) {
            time = 0;
            tractor.interval = setInterval(function () {
                eventOnAnimate.data = { sampleData: ++time };
                tractor.dispatchEvent(eventOnAnimate);
            });
        });

        tractor.addEventListener('onanimate', function (e) {
            if (e.data.sampleData === 1600) {
                cards[0].classList.remove('d-none-cardTractor')
            } else if (e.data.sampleData === 3200) {
                cards[1].classList.remove('d-none-cardTractor')
            } else if (e.data.sampleData === 4900) {
                cards[2].classList.remove('d-none-cardTractor')
            }
        });

        var fadeTarget = document.getElementById("body-tractor");
        var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 1;
            } else {
                clearInterval(fadeEffect);
            }
        }, 4000);*/
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="Com a tecnologia é possível criar soluções para aumentar a produtividade, reduzir custos e desperdícios, aumentando a qualidade e facilitando os processos diários no agronegócio."></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>
                <Header />
                <div className="bg-agro">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <Animated
                                        animationIn="bounceInLeft"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        animationInDelay={200}
                                    >
                                        <h2 className="title-banner">
                                            {this.state.language === false
                                                ? "Agregando valor ao Agronegócio"
                                                : "Bringing value to Agribusiness"
                                            }
                                        </h2>
                                    </Animated>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="gray-background pt--20">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false
                                    ? "Agronegócio"
                                    : "Agribusiness"
                                }
                            </h2>
                        </div>
                        <div className="row align-items-center">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-right" data-aos-duration="500">
                                        <div className="border-left-img">
                                            <img className="img-services" src={require('../../../assets/images/agro/agro.jpg').default} alt="Agronegócio" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div className="tab-inner history-inner">
                                        <div className="inner">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue ">
                                                    {this.state.language === false
                                                        ? "Um dos principais setores da economia brasileira que envolve atividades de três setores."
                                                        : "One of the major sectors of Brazilian economy, that covers activities in three fields."
                                                    }
                                                </p>
                                            </div>
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <ListOne language={this.state.language} />
                                            </div>
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "A utilização da tecnologia torna-se cada vez mais intensa nessa área."
                                                        : "The use of technology within these fields is becoming ever more intense."
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background pt--20">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false
                                    ? "Tecnologia"
                                    : "Technology"
                                }
                            </h2>
                        </div>
                        <div className="row align-items-center">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div className="tab-inner history-inner">
                                        <div className="inner">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <h3 className="mb--40 mb_sm--20 fontWeight400 font--34">
                                                    {this.state.language === false
                                                        ? "Otimizar processos e oferecer praticidade ao produtor rural"
                                                        : "Optimizing procedures and offering practicality to farmers"
                                                    }
                                                </h3>
                                            </div>
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue ">
                                                    {this.state.language === false
                                                        ? "Com a tecnologia é possível criar soluções para aumentar a produtividade, reduzir custos e desperdícios, aumentando a qualidade e facilitando os processos diários."
                                                        : "Through technology it’s possible to create solutions to improve productivity, reducing costs and wastage, improving quality and facilitating the daily tasks."
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                    <div data-aos="fade-left" data-aos-duration="500">
                                        <div className="border-right-img">
                                            <img className="img-services" src={require('../../../assets/images/agro/agroTech.jpg').default} alt="Agronegócio" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background ptb--50">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false
                                    ? "Soluções"
                                    : "Solutions"
                                }
                            </h2>
                        </div>
                        <BoxIconNine language={this.state.language} />
                    </div>
                </div>
                {/* Start Footer Area*/}
                {(this.state.showAnimation && (
                    <div id="tractor">
                        <div className="teste" id="body-tractor">
                            <div className="smoke">
                                <div className="s"></div>
                                <div className="s"></div>
                                <div className="s"></div>
                            </div>
                            <div className="exhaust"></div>
                            <div className="ventilation"></div>
                            <div className="window"></div>
                            <div className="wheel"></div>
                            <div className="wheel-inner"></div>
                            <div className="wheel-small"></div>
                            <div className="wheel-small-inner"></div>
                        </div>
                    </div>
                ))}
                <div className="blue-background pb--80 pt--40">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <div className="row">
                                <div className="col">
                                    <h3 className="text-investment text-white">
                                        {this.state.language === false
                                            ? "Conheça um pouco mais sobre:"
                                            : "Get to know more about:"
                                        }
                                    </h3>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="/logistic">
                                        <div className="demo_wrap">
                                            <h3>
                                                
                                                {this.state.language === false
                                                    ? "Logística Veicular"
                                                    : "Vehicle Logistics"
                                                }
                                            </h3>
                                            <img src={require(`../../../assets/images/boxIcon/logistic.jpg`).default} alt="iot" />
                                        </div>
                                    </a>
                                </div>
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="/ioT">
                                        <div className="demo_wrap">
                                            <h3>ioT</h3>
                                            <img src={require(`../../../assets/images/boxIcon/iot.jpg`).default} alt="Agronegócio" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                {/* End Footer Area*/}
            </Fragment>
        );
    }
}

export default Agro;
