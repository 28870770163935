import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import { setData, getData } from '../../services/storage/index';

class Header extends Component {

    constructor(props) {
        super(props);
        this.changeTranslate = this.changeTranslate.bind(this);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        window.addEventListener('load', function () {
            //console.log('All assets are loaded')
        })
        this.state = {
            language: getData('language')
        }
    }

    componentDidMount() {
        if (getData('language') === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }

        const elements = document.querySelectorAll('.has-dropdown > a');
        for (const i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    }

    headerSticky() {
        window.onscroll = function () { myFunction() };

        // Get the header
        var header = document.getElementById("myHeader");

        // Get the offset position of the navbar
        var sticky = header.offsetTop;;

        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }

    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    changeTranslate() {
        setData(!this.state.language)
        this.setState({ language: getData('language') })
        window.location.reload();
    }

    render() {
        return (
            <header className="header color--black" id="myHeader">
                <div className="header-wrapper">
                    <div className="branding">
                        <div className="logo">
                            <a href="/">
                                <img
                                    id="logo-header"
                                    src={require('../../assets/images/logo/logoHeader.png').default}
                                    alt="Logo-CodeScript"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="page-navigation">
                        <nav className="mainmenu-nav">
                            <ul className="mainmenu">
                                <li>
                                    <a href="/" className="mainmenu-a" ><span>{this.state.language === false ? 'Início' : 'Home'}</span></a>
                                </li>
                                <li className="has-dropdown"><a className="mainmenu-a no-cursor"><span>{this.state.language === false ? 'Sobre' : 'About Us'}</span></a>
                                    <ul className="submenu">
                                        <li>
                                            <a href="/about" className="no-padding">{`▶ `}{this.state.language === false ? 'Quem somos' : 'Who we are'}</a>
                                        </li>
                                        <li>
                                            <a href="/outsourcing">{`▶ `}Outsourcing</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="has-dropdown"><a className="mainmenu-a no-cursor"><span>{this.state.language === false ? 'Atuação' : 'Work'}</span></a>
                                    <ul className="submenu">
                                        <li>
                                            <a href="/medicine">{`▶ `}{this.state.language === false ? 'Saúde' : 'Health'}</a>
                                        </li>
                                        <li>
                                            <a href="/agro">{`▶ `}{this.state.language === false ? 'Agronegócio' : 'Agribusiness'}</a>
                                        </li>
                                        <li>
                                            <a href="/ioT">{`▶ `}ioT</a>
                                        </li>
                                        <li>
                                            <a href="/logistic">{`▶ `}{this.state.language === false ? 'Logística Veicular' : 'Vehicle Logistics'}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/web3" className="mainmenu-a"><span>{this.state.language === false ? 'Web 3.0' : 'Web 3.0'}</span></a>
                                </li>
                                    <li>
                                        <a href="/career" className="mainmenu-a"><span>{this.state.language === false ? 'Carreira' : 'Career'}</span></a>
                                    </li>
                                    <li>
                                        <a href="/contact" className="mainmenu-a"><span>{this.state.language === false ? 'Contato' : 'Contact'}</span></a>
                                    </li>
                                <li className="touch-area" onClick={() => this.changeTranslate()}>
                                    <div className="row align-items-center">
                                        <div className="translate" >
                                            {this.state.language === false
                                                ?
                                                <img src={require(`../../assets/images/preview/brazil.svg`).default} alt="pt-br" />
                                                :
                                                <img src={require(`../../assets/images/preview/united-states.svg`).default} alt="us-en" />
                                            }
                                        </div>
                                        <a className="text-white ml--10" style={{ fontSize: 14 }}>
                                            {this.state.language === false
                                                ? 'pt-BR'
                                                : 'en-US'
                                            }
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger"><FiMenu color="#fff" /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX color="#fff" /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;