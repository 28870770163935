import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import { Animated } from "react-animated-css";
import AOS from 'aos';
import { getData, setData } from "../../../services/storage";

class Logistic extends Component {

    state = {
        thePosition: 0,
        showAnimation: false,
        language: getData('language')
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
        // or simply just AOS.init();
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight - document.documentElement.clientHeight

        const scrolled = winScroll / height

        this.setState({
            thePosition: scrolled,
        })

        if (this.state.thePosition > 0.3500) {
            if (window.screen.width < 640 || window.screen.height < 480) {
                this.setState({ showAnimation: false })
            } else if (window.screen.width < 1024 || window.screen.height < 768) {
                this.setState({ showAnimation: false })
            } else {
                this.setState({ showAnimation: true })
            }
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="desenvolvimentos de softwares na qual criamos soluções para monitoramento veicular, gestão de frotas, gerenciamento de risco, roteirização e jornada de trabalho."></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>
                <Header />
                <div className="bg-logistic">
                    <div className="container">
                        <h2 className="title-banner">
                            {this.state.language === false
                                ? "Traçando a rota para o seu negócio"
                                : "Charting routes for your business"
                            }
                        </h2>
                    </div>
                </div>
                <div className="gray-background pt--20">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false
                                    ? "Logística Veicular"
                                    : "Vehicle Logistics"
                                }
                            </h2>
                        </div>
                        <div className="row align-items-center pt--30">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div data-aos="fade-right" data-aos-duration="500">
                                        <div className="border-left-img">
                                            <img className="img-services" src={require('../../../assets/images/logistic/logistic.jpg').default} alt="Logistica Veicular" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div className="tab-inner history-inner">
                                        <div className="inner">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "Empresas precisam, de diversas formas, atravessar bairros, cidades e rodovias no decorrer de suas atividades"
                                                        : "Enterprises needs to, in many different ways, cross through districts, cities and highways to execute their activities."
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "Estes deslocamentos tomam tempo, custam dinheiro e inclusive podem envolver riscos para o negócio e seus colaboradores."
                                                        : "All this moving around takes time, costs money and may even involve risks for your business and its employees."
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "Conhecer o melhor caminho a seguir e gerenciar veículos em trajeto não é apenas uma conveniência, é uma necessidade."
                                                        : "To know the best path to follow and managing vehicles during traversal is not merely a convenience, but a necessity."
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gray-background pt--20">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <h2 className="title-justify-center">
                                {this.state.language === false
                                    ? "Tecnologia"
                                    : "Technology"
                                }
                            </h2>
                        </div>
                        <div className="row align-items-center pt--20">
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div className="tab-inner history-inner">
                                        <div className="inner">
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "Hoje possuímos extensas bases cartográficas digitais: o mundo inteiro catalogado com dados de ruas, imagens áreas tiradas por satélite e mesmo fotos imersivas de nossas ruas."
                                                        : "Nowadays we have extensive digital cartographic bases: the entire world is cataloged, with data of roads, satellite imagery aerial photos and even immersive photos of our streets."
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <div data-aos="fade-up" data-aos-duration="500">
                                                <p className="text-blue">
                                                    {this.state.language === false
                                                        ? "A interação com estes tipos de dados se torna ainda mais rica quando se agrega informações de negócios e equipamentos capazes de interagir com o mundo exterior."
                                                        : "The interaction with these kinds of data becomes even richer when it’s aggregated with business information and equipment capable of interacting with the external world. "
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="landing-hero-inner pt_md--20 pt_sm--0">
                                    <div data-aos="fade-left" data-aos-duration="500">
                                        <div className="border-right-img">
                                            <img className="img-services" src={require('../../../assets/images/logistic/logisticTech.jpg').default} alt="Logistica Veicular" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="gray-background pt--120">
                    <div className="container">
                        {this.state.showAnimation === true && (
                            <div className="row ">
                                <div className="mt_sm--30 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div className="row-truck">
                                        <div className="div-diagonal-1"></div>
                                        <div className="Truck1">
                                            <img
                                                src={require('../../../assets/images/logo/logo_codescript3.png').default}
                                                alt="Logistica Veicular"
                                            />
                                            <div className="Truck1-cab"></div>
                                            <div className="Truck1-smoke"></div>
                                        </div>
                                    </div>
                                    <div className="row-truck">
                                        <div className="div-diagonal-2"></div>
                                        <div className="Truck2">
                                            <img
                                                src={require('../../../assets/images/logo/logo_codescript3.png').default}
                                                alt="Logistica Veicular"
                                            />
                                            <div className="Truck2-cab"></div>
                                            <div className="Truck2-smoke"></div>
                                        </div>
                                    </div>
                                    <div className="row-truck">
                                        <div className="div-diagonal-3"></div>
                                        <div className="Truck3">
                                            <img
                                                src={require('../../../assets/images/logo/logo_codescript3.png').default}
                                                alt="Logistica Veicular"
                                            />
                                            <div className="Truck3-cab"></div>
                                            <div className="Truck3-smoke"></div>
                                        </div>
                                    </div>

                                </div>
                                <div className="mt_sm--30 col-lg-8 col-md-8 col-sm-8 col-12">
                                    <Animated
                                        animationIn="bounceInRight"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        animationInDelay={3000}
                                    >
                                        <h2 className="title-justify-center">
                                            {this.state.language === false
                                                ? "Soluções"
                                                : "Solutions"
                                            }
                                        </h2>
                                        <p className="text-blue">
                                            {this.state.language === false
                                                ? "Algumas de nossas experiências incluem desenvolvimentos de softwares na qual criamos soluções para monitoramento veicular, gestão de frotas, gerenciamento de risco, roteirização e jornada de trabalho."
                                                : "Some of our experiences includes the development of software in which we’ve created solutions for vehicle monitoring, fleet management, risk management, routing and workload."
                                            }
                                        </p>
                                    </Animated>
                                </div>

                            </div>
                        )}
                        <div className="row align-items-center pt--100">
                            <div className="mt_sm--30 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="card-blue">
                                    <div className="content">
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h5 className="heading">
                                                {this.state.language === false
                                                    ? "Monitoramento e Gestão de frota"
                                                    : "Monitoring and Fleet Management"
                                                }
                                            </h5>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <p className="card-description" >
                                                {this.state.language === false
                                                    ? "Comunique-se via sistema com os equipamentos e tecnologias de seus veículos. Não apenas para monitorar sua localização atual, mas realizar um trabalho completo de supervisão de sua frota, fazendo tomada de decisão com base nos dados reais de cada veículo."
                                                    : "Communicate through a web system with the equipment and technologies of your vehicles. Not just to monitor its current location, but to achieve complete supervision of your fleet, making decisions based on real data of each vehicle."
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-4 col-md-4 col-sm-4 col-12 img-justify">
                                <div data-aos="fade-left" data-aos-duration="500">
                                    <img className="img-card-blue" src={require('../../../assets/images/logistic/map.svg').default} alt="Logistica Veicular" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center pt--100">
                            <div className="mt_sm--30 col-lg-4 col-md-4 col-sm-4 col-12 img-justify">
                                <div data-aos="fade-right" data-aos-duration="500">
                                    <img className="img-card-blue" src={require('../../../assets/images/logistic/router.svg').default} alt="Logistica Veicular" />
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="card-blue">
                                    <div className="content">
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h5 className="heading">
                                                {this.state.language === false
                                                    ? "Roteirização"
                                                    : "Routing"
                                                }
                                            </h5>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <p className="card-description">
                                                {this.state.language === false
                                                    ? "Combinar dados cartográficos atualizados com as regras do seu negócio para encontrar o melhor trajeto a ser seguido. O cálculo para gerar as rotas objetiva resultados que entregam o caminho mais econômico e viável, de acordo com os pontos que precisam ser visitados."
                                                    : "Combining up to date cartographic bases with your company’s business logic to find the best path to your destinations. The output of such processing generates results that delivers the fastest, most economic and viable pathway, based on all the spots that need to be visited."
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center pb--100 pt--100">
                            <div className="mt_sm--30 col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="card-blue">
                                    <div className="content">
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <h5 className="heading">
                                                {this.state.language === false
                                                    ? "Jornada de trabalho"
                                                    : "Workload"
                                                }
                                            </h5>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="500">
                                            <p className="card-description">
                                                {this.state.language === false
                                                    ? "Gerenciar criteriosamente a jornada de trabalho do condutor através da intermediação por um sistema automatizado que segue as regras da empresa e possui conformidade com a legislação, aumentando a segurança jurídica das empresas."
                                                    : "An insightful management of a driver’s working day through the implementation of automated systems that abide to the company’s rules and has conformity to current legislation, improving your business’ juridical security."
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt_sm--30 col-lg-4 col-md-4 col-sm-4 col-12 img-justify">
                                <div data-aos="fade-left" data-aos-duration="500">
                                    <img className="img-card-blue" src={require('../../../assets/images/logistic/journey.svg').default} alt="Logistica Veicular" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blue-background pb--80 pt--40">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-duration="500">
                            <div className="row">
                                <div className="col">
                                    <h3 className="text-investment text-white">
                                        {this.state.language === false
                                            ? "Conheça um pouco mais sobre:"
                                            : "Get to know more about:"
                                        }
                                    </h3>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="/agro">
                                        <div className="demo_wrap">
                                            <h3>
                                                {this.state.language === false
                                                    ? "Agronegócio"
                                                    : "Agribusiness"
                                                }
                                            </h3>
                                            <img src={require(`../../../assets/images/boxIcon/agro.jpg`).default} alt="Medicine" />
                                        </div>
                                    </a>
                                </div>
                                <div className="mt_sm--30 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="/medicine">
                                        <div className="demo_wrap">
                                            <h3>
                                                {this.state.language === false
                                                    ? "Saúde"
                                                    : "Health"
                                                }
                                            </h3>
                                            <img src={require(`../../../assets/images/boxIcon/medicine.jpg`).default} alt="Logistica Veicular" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Footer Area*/}
                <Footer />
                {/* End Footer Area*/}
            </Fragment>
        );
    }
}

export default Logistic;
