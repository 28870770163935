import { Helmet } from "react-helmet";
import React, { Component, Fragment } from "react";
import Footer from '../../components/footer/Footer';
import Header from "../../components/header/Header";
import { getData, setData } from "../../services/storage";

class Privacy extends Component {

    state = { language: getData('language') }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        if (this.state.language === null) {
            var userLang = navigator.language || navigator.userLanguage;
            if (userLang === 'pt-BR') {
                setData(false)
                this.setState({ language: false })
            }
            else {
                setData(true)
                this.setState({ language: true })
            }
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>CodeScript - Software</title>
                    <meta name="description" content="Nós respeitamos a sua privacidade!"></meta>
                    <meta name="keywords" content="outsourcing, web 3.0, software, development, programming, desenvolvimento, agro, saúde, health, logítisca veícular, internet das coisas, iot, ipfs, ntf, meta verso, bitcoin, ethereum" />
                </Helmet>

                <Header />

                <div className="landing-hero-area menu-bg pt--30">
                    <div className="container">
                        {this.state.language === false ? (
                            <>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h2 className="text-white">
                                                Termos e Políticas de Privacidade
                                            </h2>
                                            <p className="text-white">
                                                Na CodeScript, privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados pessoais dos nossos usuários/clientes. Por isso, esta presente Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações de clientes ou outras pessoas que acessam ou usam nosso site.
                                            </p>
                                            <p className="text-white">
                                                Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas formas descritas nesta Política, sob as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro ou internacional (GDPR ou similares) aplicáveis.
                                            </p>
                                            <p className="text-white">
                                                Dessa forma, a Code Script LTDA doravante denominada simplesmente como “CodeScript”, inscrita no CNPJ/MF sob o nº 21.185.972/0001-78, no papel de Controladora de Dados, obriga-se ao disposto na presente Política de Privacidade.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                1. Quais dados coletamos sobre você e para qual finalidade?
                                            </h3>
                                            <p className="text-white">
                                                Nosso site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação de serviços e aprimorar a experiência de uso.
                                            </p>
                                            <h4 className="text-white">
                                                1.1 Dados pessoais fornecidos pelo titular
                                            </h4>
                                            <ul className="list-style--1">
                                                {[
                                                    'Nome, finalidade de identificação',
                                                    'Email, finalidade de contato',
                                                    'Telefone, finalidade de contato',
                                                    'Currículo pessoal, finalidade de análise para futuras oportunidades de trabalho ',
                                                ]
                                                    .map((name, index) => {
                                                        return <li key={index} className="text-white"><i className="fa fa-check"></i>{name}</li>;
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                2. Como coletamos os seus dados?
                                            </h3>
                                            <p className="text-white">
                                                Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte forma:
                                            </p>
                                            <ul className="list-style--1">
                                                {[
                                                    'Através dos formulários preenchidos pelo próprio usuário das páginas: “Contato”, “Carreira” e “Quem somos”'
                                                ]
                                                    .map((name, index) => {
                                                        return <li key={index} className="text-white"><i className="fa fa-check"></i>{name}</li>;
                                                    })}
                                            </ul>
                                            <h4 className="text-white">
                                                2.1 Consentimento
                                            </h4>
                                            <p className="text-white">
                                                É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza a CodeScript a tratar seus dados.
                                            </p>
                                            <p className="text-white">
                                                Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento.
                                            </p>
                                            <p className="text-white">
                                                O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando o compromisso de transparência e boa-fé da CodeScript para com seus usuários/clientes, seguindo as regulações legislativas pertinentes.
                                            </p>
                                            <p className="text-white">
                                                Ao utilizar os serviços da CodeScript e fornecer seus dados pessoais, você está ciente e consentindo com as disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los.
                                            </p>
                                            <p className="text-white">
                                                A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento. É importante destacar que a revogação do consentimento para o tratamento dos dados pode implicar a impossibilidade da performance adequada de alguma funcionalidade do site que dependa da operação. Tais consequências serão informadas previamente.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                3. Quais são os seus direitos?
                                            </h3>
                                            <p className="text-white">
                                                A CodeScript assegura a seus usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:
                                            </p>
                                            <ul className="list-style--1">
                                                {[
                                                    'Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.',
                                                    'Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio eletrônico, seguro e idôneo.',
                                                    'Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.',
                                                    'Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação através da anonimização, bloqueio ou eliminação.',
                                                    'Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que a CodeScript trata a seu respeito.',
                                                    'Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.',
                                                    'Revogar seu consentimento, desautorizando o tratamento de seus dados.',
                                                    'Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da negativa.'
                                                ]
                                                    .map((name, index) => {
                                                        return <li key={index} className="text-white"><i className="fa fa-check"></i>{name}</li>;
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                4. Como você pode exercer seus direitos de titular?
                                            </h3>
                                            <p className="text-white">
                                                Para exercer seus direitos de titular, você deve entrar em contato com a CodeScript através dos seguintes meios disponíveis:
                                            </p>
                                            <p className="text-white">
                                                lgpd@codescript.com.br
                                            </p>
                                            <p className="text-white">
                                                De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação, é possível que solicitemos documentos ou demais comprovações que possam comprovar sua identidade. Nessa hipótese, você será informado previamente.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                5. Como e por quanto tempo seus dados serão armazenados?
                                            </h3>
                                            <p className="text-white">
                                                Seus dados pessoais coletados pela CodeScript serão utilizados e armazenados durante o tempo necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.
                                            </p>
                                            <p className="text-white">
                                                Findado o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei geral de proteção de dados, a saber:
                                            </p>
                                            <p className="text-white">
                                                I - cumprimento de obrigação legal ou regulatória pelo controlador;
                                            </p>
                                            <p className="text-white">
                                                II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
                                            </p>
                                            <p className="text-white">
                                                III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei;
                                            </p>
                                            <p className="text-white">
                                                IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
                                            </p>
                                            <p className="text-white">
                                                Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados.
                                            </p>
                                            <p className="text-white">
                                                O armazenamento de dados coletados pela CodeScript reflete o nosso compromisso com a segurança e privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a confidencialidade, integridade e inviolabilidade dos seus dados. Além disso, também contamos com medidas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                6. O que fazemos para manter seus dados seguros?
                                            </h3>
                                            <p className="text-white">
                                                Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.
                                            </p>
                                            <p className="text-white">
                                                Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.
                                            </p>
                                            <p className="text-white">
                                                Entre as medidas que adotamos, destacamos as seguintes:
                                            </p>
                                            <ul className="list-style--1">
                                                {[
                                                    'Apenas pessoas autorizadas têm acesso a seus dados pessoais;',
                                                    'O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade; ',
                                                    'Seus dados pessoais são armazenados em ambiente seguro e idôneo;',
                                                    'A CodeScript se compromete a adotar as melhores posturas para evitar incidentes de segurança. Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência ou imprudência do próprio usuário/cliente;',
                                                    'Em caso de incidentes de segurança que possa gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.'
                                                ]
                                                    .map((name, index) => {
                                                        return <li key={index} className="text-white"><i className="fa fa-check"></i>{name}</li>;
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                7. Com quem seus dados podem ser compartilhados?
                                            </h3>
                                            <p className="text-white">
                                                Tendo em vista a preservação de sua privacidade, a CodeScript não compartilhará seus dados pessoais com nenhum terceiro não autorizado, exceto nos seguintes casos:
                                            </p>
                                            <p className="text-white">
                                                I - Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes;
                                            </p>
                                            <p className="text-white">
                                                II - Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática;
                                            </p>
                                            <p className="text-white">
                                                III - Proteção dos direitos da CodeScript em qualquer tipo de conflito, inclusive os de teor judicial.
                                            </p>
                                            <h4 className="text-white">
                                                7.1 Transferência internacional de dados
                                            </h4>
                                            <p className="text-white">
                                                Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou  possuir instalações localizadas em países estrangeiros. Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais legislações brasileiras e internacionais de proteção de dados.
                                            </p>
                                            <p className="text-white">
                                                Nesse sentido, a CodeScript se compromete a sempre adotar eficientes padrões de segurança cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências legislativas.
                                            </p>
                                            <p className="text-white">
                                                Ao concordar com essa Política de Privacidade, você concorda com esse compartilhamento, que se dará conforme as finalidades descritas no presente instrumento.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                8. Cookies ou dados de navegação
                                            </h3>
                                            <p className="text-white">
                                                A CodeScript faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do site. Em suma, os Cookies são utilizados para aprimorar a experiência de uso.
                                            </p>
                                            <p className="text-white">
                                                Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.
                                            </p>
                                            <p className="text-white">
                                                A CodeScript utiliza os seguintes Cookies:
                                            </p>
                                            <ul className="text-white">
                                                <li className="text-white">
                                                    <h4 className="text-white">
                                                        Análitico
                                                    </h4>
                                                </li>
                                                <ul>
                                                    <li className="text-white">
                                                        _ga_SN62XYMSTE
                                                        <ul className="text-white">
                                                            O Google Analytics é uma ferramenta que permite analisar o comportamento/engajamento dos usuários em um site/app. De acordo com os termos da LGPD, o Google Analytics é considerado um processador de dados. O Google Analytics coleta cookies primários, dados relacionados ao dispositivo/navegador, o endereço IP e as atividades no site/app para avaliar e coletar estatísticas sobre as interações dos usuários nos sites e/ou aplicativos que o utilizam. Os cookies de publicidade do Google são coletados e utilizados para ativar funcionalidades como o remarketing na Rede de Display do Google. O Google proíbe o envio de informações de identificação pessoal (PII) dos usuários ao Google Analytics. Leia mais em: https://support.google.com/analytics/answer/6004245?hl=pt-BR

                                                        </ul>
                                                    </li>
                                                    <li className="text-white">
                                                        _ga
                                                        <ul className="text-white">
                                                            O Gerenciador de tags do Google é um sistema que permite atualizar de forma simples e rápida códigos de acompanhamento e fragmentos de códigos relacionados (conhecidos como tags) no seu site ou app para dispositivos móveis. Leia mais em: https://support.google.com/tagmanager/answer/6102821?hl=pt-BR

                                                        </ul>
                                                    </li>
                                                </ul>
                                            </ul>
                                            <ul className="text-white">
                                                <li className="text-white">
                                                    <h4 className="text-white">
                                                        Funcional
                                                    </h4>
                                                </li>
                                                <ul>
                                                    <li className="text-white">
                                                        language
                                                        <ul className="text-white">
                                                            O cookie de linguagem é obrigatório para manter o bom funcionamento do site codescript.com.br e é utilizado para armazenar a linguagem de navegação preferencial do usuário dentro das páginas web do mesmo.
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </ul>
                                            <p className="text-white">
                                                Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
                                            </p>
                                            <p className="text-white">
                                                Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies. Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:
                                            </p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Se você usa o Internet Explorer.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s" target="_blank">Se você usa o Firebox.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank">Se você usa o Safari.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR" target="_blank">Se você usa o Google Chrome.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">Se você usa o Microsoft Edge.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Se você usa o Opera.</a></p>
                                            <p className="text-white">Você pode ter maiores informações sobre os Cookies que utilizamos e como eles funcionam na nossa Política de Cookies, disponível neste link: <a className="link-hover privacy" rel="noreferrer" href="" target="_blank">Política de Cookies</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                9. Alteração desta Política de Privacidade
                                            </h3>
                                            <p className="text-white">
                                                A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em: 23 de Dezembro de 2021.
                                            </p>
                                            <p className="text-white">
                                                Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. Recomendamos que você a revise com frequência.
                                            </p>
                                            <p className="text-white">
                                                Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.
                                            </p>
                                            <p className="text-white">
                                                Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você as consente.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                10. Responsabilidade
                                            </h3>
                                            <p className="text-white">
                                                A CodeScript prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.
                                            </p>
                                            <p className="text-white">
                                                Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e zelando por seu cumprimento.
                                            </p>
                                            <p className="text-white">
                                                Além disso, também assumimos o compromisso de buscar condições técnicas e organizativas seguramente aptas a proteger todo o processo de tratamento de dados.
                                            </p>
                                            <p className="text-white">
                                                Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento de dados realizado pela CodeScript, comprometemo-nos a segui-las.
                                            </p>
                                            <h4 className="text-white">
                                                10.1 Isenção de responsabilidade
                                            </h4>
                                            <p className="text-white">
                                                Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a CodeScript não se responsabiliza por:
                                            </p>
                                            <p className="text-white">
                                                I - Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento. Destacamos que a responsabilidade em relação à confidencialidade dos dados de acesso é do usuário.
                                            </p>
                                            <p className="text-white">
                                                II - Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada da CodeScript. Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias.
                                            </p>
                                            <p className="text-white">
                                                III - Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos serviços da CodeScript; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de inteiramente responsabilidade do usuário/cliente.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb--50">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                11. Encarregado de Proteção de Dados
                                            </h3>
                                            <p className="text-white">
                                                A CodeScript disponibiliza os seguintes meios para que você possa entrar em contato conosco para exercer seus direitos de titular: lgpd@codescript.com.br.
                                            </p>
                                            <p className="text-white">
                                                Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode entrar em contato com o nosso Encarregado de Proteção de Dados Pessoais, através dos seguintes canais:
                                            </p>
                                            <h4 className="text-white">
                                                José Érico C. Soares - Data Protection Officer (DPO)
                                            </h4>
                                            <h5 className="text-white">
                                                lgpd@codescript.com.br
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--20 pt_md--20 pt_sm--0">
                                            <h2 className="text-white">
                                                Privacy Policy
                                            </h2>
                                            <p className="text-white">
                                                At CodeScript, privacy and security are priorities and we are compromised with transparency with the treatment of the personal data of our users and clients. Thus, the present Privacy Policy establishes how the collection of client or user data who visits our website is made, used and transferred.
                                            </p>
                                            <p className="text-white">
                                                Using our services, you understand that we will collect and use your personal information in the ways described within this Policy, under the norms of Brazilian Data Protection federal law (LGPD, Lei Federal 13.709/2018), consumerists dispositions within federal law, among other applicable norms of Brazilian legal order and international user data protection (GDPR, and such).
                                            </p>
                                            <p className="text-white">
                                                As such, Code Script LTDA, from now on denominated simply as “CodeScript”, under Brazilian National Register of Legal Entities number 21.185.972/0001-78, under the agent of Data Controller, obligates itself within what’s stated in the present Privacy Policy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                1. What data are collected about you and for what goal?
                                            </h3>
                                            <p className="text-white">
                                                Our website collects and use some personal data to enable the installment of services and improving user experience.
                                            </p>
                                            <h4 className="text-white">
                                                1.1 Personal data provided by the user
                                            </h4>
                                            <ul className="list-style--1">
                                                {[
                                                    'Name, for the finality of identification',
                                                    'E-mail, for the finality of contacting',
                                                    'Telephone, for the finality of contacting',
                                                    'Personal curriculum, for the finality of analysis for future job opportunities',
                                                ]
                                                    .map((name, index) => {
                                                        return <li key={index} className="text-white"><i className="fa fa-check"></i>{name}</li>;
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                2. How do we collect your data?
                                            </h3>
                                            <p className="text-white">
                                                The collection of your personal data takes place as follows:
                                            </p>
                                            <ul className="list-style--1">
                                                {[
                                                    'Through forms filled by the user themselves within the pages “Contact”, “Career” and “About Us”.'
                                                ]
                                                    .map((name, index) => {
                                                        return <li key={index} className="text-white"><i className="fa fa-check"></i>{name}</li>;
                                                    })}
                                            </ul>
                                            <h4 className="text-white">
                                                2.1 Consent
                                            </h4>
                                            <p className="text-white">
                                                It’s through your consent that we treat your personal data. The consent is a free, informed and unmistakable manifestation that you authorize CodeScript to treat your data.
                                            </p>
                                            <p className="text-white">
                                                As such, in consonance with Brazilian General Data Protection Law, your data will only be collected, treated and stored under previous and explicit consent.
                                            </p>
                                            <p className="text-white">
                                                Your consent will be obtained specifically for each purposes described beforehand thus stating CodeScript’s good will and compromise with transparency among its users and clients, following the relevant legislative regulations.
                                            </p>
                                            <p className="text-white">
                                                By using CodeScript’s services and supplying your personal data, you are aware of and consenting with the dispositions of this Privacy Policy, alongside being aware of your rights and how to exercise them.
                                            </p>
                                            <p className="text-white">
                                                At any given moment and at no cost, you will be able to revoke your consent. It’s important to highlight that revoking consent for data treatment implies the impossibility of proper performance of any of the functionalities of this website that relies on them. Such consequences will be informed beforehand.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                3. What are your rights?
                                            </h3>
                                            <p className="text-white">
                                                CodeScript assures its clients and users their rights as holders provided for in article 18 of the Brazilian General Data Protection Law. That way you can, for free and at any time:
                                            </p>
                                            <ul className="list-style--1">
                                                {[
                                                    'Confirm the existence of data treatment, simplified or in complete, clear format.',
                                                    'Access your data, being able to request them in a legible, securely and reputable copy in printed form or electronically.',
                                                    'Correct your data when requesting edition, correction or updating them.',
                                                    'Limiting your data whenever unnecessary, excessive or treated in unconformity with legislation through anonymization, blocking or deleting.',
                                                    'Request portability of your data, through a report of the registration data CodeScript handles about you.',
                                                    'Eliminate the data that was handled by consent, expect when provided by law.',
                                                    'Revoke your consent, unauthorizing the treatment of your data.',
                                                    'Inform you about the possibility of not providing your consent and about the consequences of such.'
                                                ]
                                                    .map((name, index) => {
                                                        return <li key={index} className="text-white"><i className="fa fa-check"></i>{name}</li>;
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                4. How can you exercise your rights as a holder?
                                            </h3>
                                            <p className="text-white">
                                                To exercise your rights as a holder, you must contact CodeScript though the above available means.
                                            </p>
                                            <p className="text-white">
                                                lgpd@codescript.com.br
                                            </p>
                                            <p className="text-white">
                                                To guarantee your correct identification as the holder of the requested personal data, it’s possible that we request documents or additional proof that can prove your identity. On such hypothesis, you will be informed beforehand.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                5. For how long your data will be stored?
                                            </h3>
                                            <p className="text-white">
                                                Your personal data collected by CodeScript will be utilized and stored during the necessary for providing the service or for the purposes listed in this Privacy Policy to be achieved, considering the rights of data subjects and controllers.
                                            </p>
                                            <p className="text-white">
                                                By the end of the period we’re storing this personal data, they will be excluded from our databases or will be anonymized, aside from the hypothesis under article 16 of Brazilian General Data Protection Law that should be know:
                                            </p>
                                            <p className="text-white">
                                                I - compliance with a legal or regulatory obligation by the controller;
                                            </p>
                                            <p className="text-white">
                                                II - study by a research body, ensuring, whenever possible, the anonymization of personal data;
                                            </p>
                                            <p className="text-white">
                                                III - transfer to a third party, provided that the data processing requirements set out in this Law are respected;
                                            </p>
                                            <p className="text-white">
                                                IV - exclusive use of the controller, its access by a third party being prohibited, and provided that the data is anonymized.
                                            </p>
                                            <p className="text-white">
                                                Thus, personal information about you that are essential for the fulfillment of legal, judicial and administrative determinations and/or for the exercise of the right of defense in judicial and administrative proceedings, will be kept, despite the exclusion of the other data.
                                            </p>
                                            <p className="text-white">
                                                The storage of data collected by CodeScript reflects our commitment to the security and privacy of your data. We employ technical protection measures and solutions capable of guaranteeing the confidentiality, integrity, and inviolability of your data. In addition, we also have security measures appropriate to the risks and access control to stored information.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                6. What will we do to keep your data safe?
                                            </h3>
                                            <p className="text-white">
                                                To keep your personal information safe, we use physical, electronic and management tools appropriate for the protection of your privacy.
                                            </p>
                                            <p className="text-white">
                                                We apply these tools taking consideration the nature of personal data, the context and finality of processing and the risks that violations create to the rights and freedoms of the collected data’s holder.
                                            </p>
                                            <p className="text-white">
                                                Among the measures we adopt, we highlight the following:
                                            </p>
                                            <ul className="list-style--1">
                                                {[
                                                    'Only authorized personnel have access to your personal data;',
                                                    'The access to your personal data is made only after a confidentiality commitment;',
                                                    'Your personal data is stored in a safe and sound environment;',
                                                    'CodeScript is committed to adopting the best measures to avoid security incidents. However, it is necessary to emphasize that no website is entirely safe and risk-free. It is possible that, despite all our security protocols, problems that are solely the fault of third-parties may occur, such as cyber attacks by hackers, or also due to the negligence or recklessness of the user/client;',
                                                    'In case of security incidents that may generate relevant risk or damage to you or any of our users/clients, we will communicate to those affected and the National Data Protection Authority about what happened, in accordance with the provisions of the Brazilian General Data Protection Law.'
                                                ]
                                                    .map((name, index) => {
                                                        return <li key={index} className="text-white"><i className="fa fa-check"></i>{name}</li>;
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                7. With whom your data can be shared with?
                                            </h3>
                                            <p className="text-white">
                                                In order to preserve your privacy, Code Script will not share your personal data with any unauthorized third party, except in the following cases:
                                            </p>
                                            <p className="text-white">
                                                Legal determination, application, requisition or court order, with competent judicial, administrative or governmental authorities;
                                            </p>
                                            <p className="text-white">
                                                II - In case of corporate changes, such as mergers, acquisitions and incorporations, automatically;
                                            </p>
                                            <p className="text-white">
                                                III - Protection of CodeScript's rights in any type of conflict, including those of a judicial nature.
                                            </p>
                                            <h4 className="text-white">
                                                7.1 International transfering of data
                                            </h4>
                                            <p className="text-white">
                                                Some third-parties with whom we share your data may be located or have their installments located in foreign countries. Under this circumstances, either way, your personal date is subject to Brazil’s and international legislations for data protection.
                                            </p>
                                            <p className="text-white">
                                                In this regard, CodeScript is committed to always adopt effective cybersecurity and data protection standards, in its best efforts to guarantee and comply with legislative requirements.
                                            </p>
                                            <p className="text-white">
                                                By agreeing with this Privacy Policy, you agree with such sharing of data, which will take place in accordance with the purposes described in this document.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                8. Cookies or navigation data
                                            </h3>
                                            <p className="text-white">
                                                CodeScript makes use of Cookies, which are text files sent by this platform to your computer and stored on it, which contain information related to browsing this website. Thus, Cookies are used to improve the user experience.
                                            </p>
                                            <p className="text-white">
                                                By accessing our website and consenting to the use of Cookies, you acknowledge and accept the use of a navigation data collection system with the use of Cookies on your device.
                                            </p>
                                            <p className="text-white">
                                                CodeScript makes use of the following Cookies:
                                            </p>
                                            <ul className="text-white">
                                                <li className="text-white">
                                                    <h4 className="text-white">
                                                        Analytics
                                                    </h4>
                                                </li>
                                                <ul>
                                                    <li className="text-white">
                                                        _ga_SN62XYMSTE
                                                        <ul className="text-white">
                                                            Google Analytics is a tool that allows you to analyze the behavior/engagement of users on a website or application. Google Analytics collects first-party cookies, device/browser related data, IP address and website/app activities to measure and collect statistics about user interactions on the websites and/or apps that use it. Google advertising cookies are collected and used to enable features such as remarketing on the Google Display Network. Google prohibits the submission of personally identifiable information (PII) of users to Google Analytics.
                                                            For further information, read more at: <a className="link-hover privacy" href="https://support.google.com/analytics/answer/6004245?hl=en" rel="noreferrer" target="_blank">https://support.google.com/analytics/answer/6004245?hl=en</a>
                                                        </ul>
                                                    </li>
                                                    <li className="text-white">
                                                        _ga
                                                        <ul className="text-white">
                                                            Google Tag Manager is a system that allows you to simply and quickly update tracking codes and related code snippets (known as tags) on your mobile website or app. For further information, read more at: <a className="link-hover privacy" href="https://support.google.com/analytics/answer/6004245?hl=en" rel="noreferrer" target="_blank">https://support.google.com/tagmanager/answer/6102821?hl=en</a>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </ul>
                                            <ul className="text-white">
                                                <li className="text-white">
                                                    <h4 className="text-white">
                                                        Functional
                                                    </h4>
                                                </li>
                                                <ul>
                                                    <li className="text-white">
                                                        language
                                                        <ul className="text-white">
                                                            The language cookie is mandatory to keep the codescript.com.br website functioning properly and it is used to store the user's preferred navigation language within its web pages.
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </ul>
                                            <p className="text-white">
                                                You may, at any time and at no cost, change permissions, block or refuse Cookies. However, revoking the consent to certain Cookies may make it impossible for some platform features to function correctly.
                                            </p>
                                            <p className="text-white">
                                                To manage your browser's cookies, you must do it so directly within your browser settings, in the Cookies management area. You can access tutorials on the topic directly from the links below:
                                            </p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">If you make use of Internet Explorer.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s" target="_blank">If you make use of Firebox.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank">If you make use of Safari.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR" target="_blank">If you make use of Google Chrome.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank">If you make use of Microsoft Edge.</a></p>
                                            <p className="text-white"><a className="link-hover privacy" rel="noreferrer" href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">If you make use of Opera.</a></p>
                                            <p className="text-white">You can have further information about the Cookies that we make use of and how they operate within you Cookies Policy, available at this link:  <a className="link-hover privacy" href="" rel="noreferrer" target="_blank">Cookies Policy</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                9. Amendments to this Privacy Policy
                                            </h3>
                                            <p className="text-white">
                                                The current version of the Privacy Policy was formulated and last updated on: December 23th, 2021.
                                            </p>
                                            <p className="text-white">
                                                We reserve the right to modify this Privacy Policy at any time, mainly depending on the adequacy of any changes made to our website or within legislative scope. We recommend that you review it frequently.
                                            </p>
                                            <p className="text-white">
                                                Any changes will take effect as of their publication on our website and we will always notify you of any changes that have occurred.
                                            </p>
                                            <p className="text-white">
                                                By using our services and providing your personal data after such modifications, you consent to them.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                10. Responsibility
                                            </h3>
                                            <p className="text-white">
                                                CodeScript provides for the responsibility of agents who work in data processing processes, in accordance with Articles 42 to 45 of the Brazilian General Data Protection Law. </p>
                                            <p className="text-white">
                                                We are committed to keeping this Privacy Policy updated, observing its provisions and ensuring compliance.
                                            </p>
                                            <p className="text-white">
                                                In addition, we are also committed to seeking technical and organizational conditions that are surely able to protect the entire data processing process.
                                            </p>
                                            <p className="text-white">
                                                In case the National Data Protection Authority requires the adoption of measures in relation to the data processing carried out by CodeScript, we undertake to follow them.
                                            </p>
                                            <h4 className="text-white">
                                                10.1 Liability disclaimer
                                            </h4>
                                            <p className="text-white">
                                                As mentioned in Item 6, although we adopt high security standards in order to avoid incidents, there is no website that is entirely risk-free. In this regard, CodeScript is not responsible for:
                                            </p>
                                            <p className="text-white">
                                                I - Any consequences arising from the negligence, recklessness or malpractice of users in relation to their individual data. We guarantee and are only responsible for the security of the data processing processes and the fulfillment of the purposes described in this document. We emphasize that the user is responsible for the confidentiality of access data.
                                            </p>
                                            <p className="text-white">
                                                II - Malicious third-party actions, such as hacker attacks, unless proven guilty or deliberate of conduct by CodeScript. We emphasize that in case of security incidents that may generate relevant risk or damage to you or any of our users/customers, we will communicate to those affected and the designated authorities about what happened and we will take the necessary procedures.
                                            </p>
                                            <p className="text-white">
                                                III - Inaccuracy of the information entered by the user/client in the records necessary for the use of CodeScript services; any consequences arising from false information or entered in bad faith are entirely the responsibility of the user/client.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb--50">
                                    <div className="mt_sm--30 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="landing-hero-inner pt--50 pt_md--20 pt_sm--0">
                                            <h3 className="text-white">
                                                11. Responsible for Personal Data Protection
                                            </h3>
                                            <p className="text-white">
                                                CodeScript provides the following means for you to contact us to exercise your rights as a personal data holder: lgpd@codescript.com.br.
                                            </p>
                                            <p className="text-white">
                                                If you have any questions about this Privacy Policy or about the personal data we handle, you can contact our Responsible for Personal Data Protection, through the following channels:
                                            </p>
                                            <h4 className="text-white">
                                                José Érico C. Soares - Data Protection Officer (DPO)
                                            </h4>
                                            <h5 className="text-white">
                                                lgpd@codescript.com.br
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div >
                <Footer />
            </Fragment >
        );
    }
}

export default Privacy;
