import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BoxIconThree extends Component {
    render() {
        let Data = [
            {
                icon: '01',
                title: this.props.language === false ? 'Agronegócio' : 'Agribusiness',
                image: 'agro.jpg',
                url: '/agro'
            },

            {
                icon: '02',
                title: 'ioT',
                image: 'iot.jpg',
                url: '/ioT'
            },

            {
                icon: '03',
                title: this.props.language === false ? 'Saúde' : 'Health',
                image: 'medicine.jpg',
                url: '/medicine'
            },

            {
                icon: '04',
                title: this.props.language === false ? 'Logística Veicular' : 'Vehicle Logistics',
                image: 'logistic.jpg',
                url: '/logistic'
            }
        ]

        let DataList = Data.map((val, i) => {
            return (
                <Col className="mt--30" lg="6" md="6" sm="6" xs="12" key={i}>
                    <a href={val.url}>
                        <div className="content">
                            <div className="demo_wrap">
                                <h3>{val.title}</h3>
                                <img src={require(`../../../assets/images/boxIcon/${val.image}`).default} style={{ width: '100%'}} alt="teste" />
                            </div>
                        </div>
                    </a>
                </Col>
            )
        })

        return (
            <Row className="mt--30">
                {DataList}
            </Row>
        )
    }
}

export default BoxIconThree;
