import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Animated } from "react-animated-css";
import { getData } from "../../services/storage";

class BoxIconNineWeb3 extends Component{
    state = { language: getData('language') };

    render(){
        return(
            <Row>
                {/* Start Single Box */}
                <Col className="mt--30" lg="3" md="6" sm="12" xs="12">
                    <div className="box-icon--9">
                        <div className="svg-inner">
                        <svg>
                            <path fill="none" d="M61,44L61,55L1,55L1,15L61,15L61,26"></path>
                            <path fill="none" d="M6,9L54,9L54,15"></path>
                            <path fill="none" d="M6,9c-2.762,0-5,2.239-5,5"></path>
                            <path fill="none" d="M43.125,26c-4.972,0-9,4.029-9,9c0,4.97,4.028,9,9,9
                                H63V26H43.125z"></path>
                            <path fill="none" d="M41,35A3,3 0,1,1 47,35A3,3 0,1,1 41,35"></path>
                        </svg>
                        </div>
                        <div className="content">
                            <h5 className="heading text-center">Bitcoin</h5>
                            <p>
                                {this.state.language === false
                                    ? 'Clique no botão abaixo para copiar o endereço da nossa carteira de bitcoin.'
                                    : 'Click the button below to copy our bitcoin wallet address.'
                                }
                            </p>
                            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1250}>
                                <div className="text-center">
                                    <button className="ar-button style-flat" type="submit"
                                        onClick={() => {
                                            navigator.clipboard.writeText(this.props.bitcoinWallet);
                                            alert('Carteira copiada');
                                        }}
                                    >
                                        <span className="button-text">
                                            {this.state.language === false
                                                ? 'BITCOIN'
                                                : 'BITCOIN'
                                            }
                                        </span>
                                    </button>
                                </div>
                            </Animated>
                        </div>
                    </div>
                </Col>
                {/* End Single Box */}

                {/* Start Single Box */}
                <Col className="mt--30" lg="3" md="6" sm="12" xs="12">
                    <div className="box-icon--9">
                        <div className="svg-inner">
                        <svg>
                            <path fill="none" d="M61,44L61,55L1,55L1,15L61,15L61,26"></path>
                            <path fill="none" d="M6,9L54,9L54,15"></path>
                            <path fill="none" d="M6,9c-2.762,0-5,2.239-5,5"></path>
                            <path fill="none" d="M43.125,26c-4.972,0-9,4.029-9,9c0,4.97,4.028,9,9,9
                                H63V26H43.125z"></path>
                            <path fill="none" d="M41,35A3,3 0,1,1 47,35A3,3 0,1,1 41,35"></path>
                        </svg>
                        </div>
                        <div className="content">
                            <h5 className="heading text-center">Ethereum</h5>
                            <p>
                                {this.state.language === false
                                    ? 'Clique no botão abaixo para copiar o endereço da nossa carteira de ethereum.'
                                    : 'Click the button below to copy our ethereum wallet address.'
                                }
                            </p>
                            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1250}>
                                <div className="text-center">
                                    <button className="ar-button style-flat" type="submit"
                                        onClick={() => {
                                            navigator.clipboard.writeText(this.props.ethereumWallet);
                                            alert('Carteira copiada');
                                        }}
                                    >
                                        <span className="button-text">
                                            {this.state.language === false
                                                ? 'ETHEREUM'
                                                : 'ETHEREUM'
                                            }
                                        </span>
                                    </button>
                                </div>
                            </Animated>
                        </div>
                    </div>
                </Col> 
                {/* End Single Box */}

                <Col className="mt--120" lg="6" md="6" sm="12" xs="12">
                    <div className="thumbnail mt--30">
                        <img src={require('../../assets/images/web3/cripto-flowers.svg').default} alt="Wallets" />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default BoxIconNineWeb3;