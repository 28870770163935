import React, { Component } from "react";

class BoxIconNine extends Component {
    render() {
        return (
            <div className="row justify-content-center">
                <div className="row-cards">
                    <div className="cardTractor">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="svg-inner">
                                    <img src={require('../../../assets/images/agro/hardwares.svg').default} alt="app landing" />
                                </div>
                                <h4>
                                    {this.props.language === false
                                        ? "Integrações de hardwares"
                                        : "Hardware Integration"
                                    }
                                </h4>
                            </div>
                            <div className="col-lg-8">
                                <p>
                                    
                                    {this.props.language === false
                                        ? "Desenvolvimento e coleta de dados em dispositivos instalados nos equipamentos utilizados pelas empresas na rotina do campo."
                                        : "Developing and collecting data from devices installed into the utilized equipment during everyday rural activities."
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-cards">
                    <div className="cardTractor">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="svg-inner">
                                    <img src={require('../../../assets/images/agro/appMobile.svg').default} alt="app landing" />
                                </div>
                                <h4>
                                    {this.props.language === false
                                        ? "Aplicações móveis"
                                        : "Mobile Applications"
                                    }
                                </h4>
                            </div>
                            <div className="col-lg-8">
                                <p>
                                    {this.props.language === false
                                        ? "Visualização de dados e envio de comandos para um determinado dispositivo através de um aplicativo móvel, executando ações que facilitam o processo das empresas e do produtor rural."
                                        : "Data visualization and input commands to a given equipment using a mobile app, easing the processes executed by companies and farmers. "
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-cards">
                    <div className="cardTractor">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="svg-inner">
                                    <img src={require('../../../assets/images/agro/webDevelopment.svg').default} alt="app landing" />
                                </div>
                                <h4>
                                    {this.props.language === false
                                        ? "Sistemas de gestão"
                                        : "Management Systems"
                                    }
                                </h4>
                            </div>
                            <div className="col-lg-8">
                                <p>
                                    {this.props.language === false
                                        ? "Desenvolvimento de sistemas para gestão através de dados que são fornecidos pelos dispositivos utilizados no processo, assim como a emissão de relatórios dessas informações."
                                        : "Developing systems for managing workflow through the data provided by the utilized tools and equipment, as well emitting reports of these information."
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoxIconNine;