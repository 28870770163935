// React Required
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './index.scss';

import {
    Home,
    About,
    Contact,
    Medicine,
    ioT,
    Agro,
    Logistic,
    Outsourcing,
    Career,
    Cookies,
    Privacy,
    Web3,
    Game,
} from './pages'

// Inner pages

import Maintenance from "./pages/Maintenance";
import NotFound from "./pages/NotFound";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //<React.StrictMode>
        <BrowserRouter>
            <Switch>
                {/* Start home pages */}
                <Route exact path="/" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route path="/ioT" component={ioT} />
                <Route path="/logistic" component={Logistic} />
                <Route path="/medicine" component={Medicine} />
                <Route path="/agro" component={Agro} />
                <Route path="/outsourcing" component={Outsourcing} />
                <Route path="/career" component={Career} />
                <Route path="/cookies" component={Cookies} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/maintenance" component={Maintenance} />
                <Route path="/web3" component={Web3} />
                <Route path="/game" component={Game} />
                <Route path="/not-found" component={NotFound} />
                {/* End home pages */}
            </Switch>
        </BrowserRouter>
    //</React.StrictMode>
);
